/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();

    const { user } = useSelector(
        (state) => ({ user: state.user.userInfo }),
        shallowEqual
    );

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {user && user.sId &&
            <div style={{
                backgroundColor: 'white', width: 120, display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img
                    // src={toAbsoluteUrl(`/media/sLogos/${user.sId}.png`)}
                    src={`https://betasoftlogos.s3.eu-central-1.amazonaws.com/logos/${user.sId}.png`}
                    alt="new"
                    style={{ width: 100, height: 'auto', margin: 10, maxHeight: 50, maxWidth: 100 }}
                />
            </div>
        }
        {!user.sId &&
            <div style={{
                backgroundColor: 'white', width: 120, display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}

            {/*end::1 Level*/}

            <li className={`menu-item menu-item-rel ${getMenuItemActive('/pregled')}`}>
                <NavLink className="menu-link" to="/pregled">
                    <span className="menu-text"><i className="fas fa-layer-group mr-1"></i> Pregled</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li>

            <li
                data-menu-toggle={layoutProps.menuDesktopToggle}
                aria-haspopup="true"
                className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive('/kandidati')}`}>
                <NavLink className="menu-link menu-toggle active" to="/kandidati">
                    <span className="menu-text"><i className="fas fa-plus mr-1"></i> Dodaj</span>
                    <i className="menu-arrow"></i>
                </NavLink>
                <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                    <ul className="menu-subnav">


                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/placanja/dodaj')}`}>
                            <NavLink className="menu-link" to="/placanja/dodaj">
                                <span className="menu-text"><i className="fas fa-money-bill-wave mr-2"></i> Plaćanje</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>

                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/kandidati/dodaj')}`}>
                            <NavLink className="menu-link" to="/kandidati/dodaj">
                                <span className="menu-text"><i className="fas fa-user-plus mr-2"></i> Kandidat</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>


                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/ispiti/dodaj')}`}>
                            <NavLink className="menu-link" to="/ispiti/dodaj">
                                <span className="menu-text"><i className="fas fa-chalkboard-teacher mr-2"></i> Ispit</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>

                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/radnici/dodaj')}`}>
                            <NavLink className="menu-link" to="/radnici/dodaj">
                                <span className="menu-text"><i className="fas fa-users-cog mr-2"></i> Zaposleni</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>


                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/vozila/dodaj')}`}>
                            <NavLink className="menu-link" to="/vozila/dodaj">
                                <span className="menu-text"><i className="fas fa-car-side mr-2"></i> Vozilo</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>

                        <li className={`menu-item menu-item-rel ${getMenuItemActive('/prostor/dodaj')}`}>
                            <NavLink className="menu-link" to="/prostor/dodaj">
                                <span className="menu-text"><i className="fas fa-building mr-2"></i> Prostor</span>
                                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </li>

            {/* <li className={`menu-item menu-item-rel ${getMenuItemActive('/placanja/izvestaj')}`}>
                <NavLink className="menu-link" to="/placanja/izvestaj">
                    <i className="far fa-file-pdf mr-2 text-danger"></i>
                    <span className="menu-text text-danger">Preuzmi godišnji izveštaj</span>
                    {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}

            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
