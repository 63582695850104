import { createSlice } from "@reduxjs/toolkit";

const initialCandidatesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    carForEdit: undefined,
    lastError: null,
    vehicleTypes: [],
    vehicleStatus: [],
    totalCountDocs: 0,
    vehicleDocs: []
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const carsSlice = createSlice({
    name: "cars",
    initialState: initialCandidatesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        carFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.carForEdit = action.payload.carForEdit;
            state.error = null;
        },
        dataFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        carCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.car);
        },

        loadConfig: (state, action) => {
            state.vehicleTypes = action.payload.vehicleTypes;
            state.vehicleStatus = action.payload.vehicleStatus;
        },
        docsFetched: (state, action) => {
            const { totalCountDocs, vehicleDocs } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.vehicleDocs = vehicleDocs;
            state.totalCountDocs = totalCountDocs;
        },
    }
});
