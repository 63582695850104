import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import OtpInput from 'react-otp-input';

import { firebaseApp } from "../../../../index";
import { snackActions } from '../../../../SnackbarUtilsConfigurator';
import firebase from "firebase";
import { PhoneNumberField } from "../../../../_metronic/_partials/controls";

const initialValues = {
	phoneNumber: "+381",
};

const LoginSchema = Yup.object().shape({
	phoneNumber: Yup.string()
		.min(10, "Minimum 10 karaktera")
		.max(15, "Najvise 15 karaktera")
		.required("Morate uneti broj telefona!"),
});

function Phone(props) {

	const [loading, setLoading] = useState(false);
	const [code, setCode] = useState(null);
	const [verificationCode, setVerificcationCode] = useState(null);

	let history = useHistory();

	useEffect(() => {
		firebase.auth().languageCode = 'sr';
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
			'size': 'invisible',
			'callback': (response) => {
			},
		}, firebaseApp);
	}, []);

	async function confirmCode() {
		try {

			const credential = firebase.auth.PhoneAuthProvider.credential(verificationCode, code);
			const result = await firebase.auth().signInWithCredential(credential);
			if (result.additionalUserInfo.isNewUser) {
				await firebase.auth().currentUser.delete();
				setLoading(false);
				snackActions.error("Broj telefona nije povezan ni sa jednim nalogom u aplikaciji.", 2000);
				setVerificcationCode(null);
				setCode(null);
			} else {
				props.login(result.user);
				setLoading(false);
			}

			return true;
		} catch (error) {
			setLoading(false);
			// console.log("err ", error);
			if (error.code === "auth/invalid-verification-code") {
				snackActions.error("Verifikacioni kod koji ste uneli nije validan. Pokušajte opet!", 2000);
			} else {
				snackActions.error("Nešto nije u redu. Molimo Vas pokušajte opet!", 2000);
			}
		}
	}

	return (
		<div className="login-form login-signin" id="kt_login_signin_form">
			{/* begin::Head */}
			<>
				<div className="text-center mb-10 mb-lg-20">
					<h3 className="font-size-h1">
						Uloguj se na svoj nalog
					</h3>
					<p className="text-muted font-weight-bold">
						Unesite Vaš broj telefona u formatu +3816xxxxxxxxx
					</p>
				</div>
				{/* end::Head */}

				{verificationCode === null && <Formik
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={LoginSchema}
					onSubmit={async (values) => {
						try {
							setLoading(true);

							const result = await firebase.auth().signInWithPhoneNumber(`+${values.phoneNumber}`, window.recaptchaVerifier);

							if (result.verificationId) {
								setVerificcationCode(result.verificationId);
							}

							setLoading(false);

							return true;
						} catch (error) {
							setLoading(false);
							if (error.code === "auth/invalid-phone-number") {
								snackActions.error("Broj telefona nije validan. Pokusajte opet!", 2000);
							} else {
								snackActions.error("Nešto nije u redu. Molimo Vas pokušajte opet!", 2000);
							}
						}
					}}
				>
					{({ handleSubmit, values, setValues }) => (
						<Form>
							<div className="form-group fv-plugins-icon-container">
								<Field
									label=""
									name="phoneNumber"
									inputProps={{ name: 'phoneNumber' }}
									component={PhoneNumberField}
									customValidation
									showValidation={false}
									inputClass="form-control form-control-solid h-auto"
								/>
							</div>

							<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
								<button
									// id="sign-in-button-phone"
									disabled={loading || values.phoneNumber.length < 12}
									type="submit"
									className={`btn btn-primary btn-block font-weight-bold px-9 py-4`}
								><i className="fas fa-mobile-alt"></i>
									<span>Pošalji kod</span>
									{loading && <span className="ml-3 spinner spinner-white"></span>}
								</button>
							</div>
						</Form>
					)}
				</Formik>
				}

				{
					verificationCode && <>
						<p className="text-muted font-weight-bold">
							Unesite verifikacioni kod koji ste dobili u SMS poruci.
						</p>
						<OtpInput
							value={code}
							shouldAutoFocus={true}
							isInputNum
							onChange={(val) => setCode(val)}
							numInputs={6}
							separator={<span>-</span>}
							inputStyle={{ width: '100%', height: 60, textSize: 18, borderWidth: 0.5 }}
						/>
					</>
				}

				{
					verificationCode &&
					<button
						onClick={confirmCode}
						disabled={code == null || code.length !== 6}
						className={`btn btn-info btn-block font-weight-bold px-9 py-4 my-3`}
					><i className="fas fa-check-circle"></i>
						<span>Potvrdi kod</span>
					</button>
				}


				<button className="btn btn-default btn-block" onClick={() => history.goBack()}>
					<i className="fas fa-arrow-left"></i> Nazad
				</button>


				<div id="sign-in-button" />
			</>
		</div>
	);
}


export default injectIntl(connect(null, auth.actions)(Phone));
