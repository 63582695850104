import { createSlice } from "@reduxjs/toolkit";

const initialSpaceState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    forEdit: undefined,
};

export const callTypes = {
    list: "list",
    action: "action"
};


export const slice = createSlice({
    name: "pc",
    initialState: initialSpaceState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        fetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        editFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.forEdit = action.payload.forEdit;
            state.error = null;
        },
        finishedCall: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
        },
    }
});
