import { toast } from "react-toastify";

export const snackActions = {
    success(msg, duration = 2000) {
        toast.success(msg, {
            position: "top-center",
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    },
    warning(msg, duration = 2000) {
        toast.warning(msg, {
            position: "top-center",
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    },
    info(msg, duration = 2000) {
        toast.info(msg, {
            position: "top-center",
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    },
    error(msg, duration = 2000) {
        toast.error(msg || DEFAULT_ERROR_MESSAGE, {
            position: "top-center",
            autoClose: duration,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    },
    noInternet(show) {
        if (show) {
            const msg = "Izgleda da trenutno nemate internet konekciju!";
            toast.info(msg, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "dark",
                toastId: "noInternetRef"
            });
        } else if (toast.isActive("noInternetRef")) {
            toast.dismiss("toastId.current")
        }
    }
}

export const DEFAULT_ERROR_MESSAGE = "Doslo je do greske!";