import React from "react";
import moment from 'moment-timezone';

export function DateTimeColumnFormatter(cellContent, row) {  
    return (
        <>
            <span className={`font-bold}`}>
                {cellContent ? moment(cellContent).tz('Europe/Belgrade').format("DD.MM.YYYY. HH:mm"): <></>}
            </span>
        </>
    );
}
