/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
	const { userInfo } = useSelector(
		(state) => ({
			userInfo: state.user.userInfo,
		}),
		shallowEqual
	);

	return (
		<Dropdown drop="down" alignRight>
			<Dropdown.Toggle
				as={DropdownTopbarItemToggler}
				id="dropdown-toggle-user-profile"
			>
				{userInfo && userInfo["firstName"] &&
					<div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
						<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
						<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
							{userInfo.firstName + ' ' + userInfo.lastName}
						</span>
						<span className="symbol symbol-35 symbol-light-success">
							<span className="symbol-label font-size-h5 font-weight-bold">{userInfo.firstName[0] + userInfo.lastName[0]}</span>
						</span>
					</div>
				}
			</Dropdown.Toggle>
			{/* <Dropdown.Menu
				className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"> 
				<div className="navi navi-spacer-x-0 pt-5">
					<div className="navi-footer  px-8 py-5">
						<Link to="/logout" className="btn btn-light-primary font-weight-bold">
							Odjavi se
						</Link>
					</div>
				</div>
			</Dropdown.Menu> */}
		</Dropdown>
	);
}
