/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import 'react-animation/dist/keyframes.css'

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function DocumentsDropdown() {

  const [key, setKey] = useState("Alerts");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" className="mr-1" alignRight onToggle={function (isOpen, event, metadata) { }}>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <button type="button" className="btn btn-outline-primary"><span className="badge badge-primary mr-2">3</span>Dokumenta</button>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              <div
                className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top">
                <Tab.Container defaultActiveKey={key}>
                  <Tab.Content className="tab-content">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-2"
                      style={{ maxHeight: "500px", position: "relative" }}
                    >
                      <a className="navi-item" href="https://drive.google.com/file/d/1aE-uEhQsGtD59QTz3Exr6psmPMg7pWRF/view?usp=sharing" target="_blank">
                        <div className="navi-link">
                          <div className="navi-icon mr-2">
                            <i className="far fa-file-pdf"></i>
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">BetaSoft - Kreiranje ugovora</div>
                            <div className="text-muted">
                              <i className="fas fa-calendar-alt createdDateFavicon"></i>01.02.2022
                              <i className="fas fa-user-edit createdDateBy"></i>BetaSoft
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className="navi-item" href="https://youtu.be/wgl5uSwsUTc" target="_blank">
                        <div className="navi-link">
                          <div className="navi-icon mr-2">
                            <i style={{ color: 'red' }} className="fab fa-youtube"></i>
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">BetaSoft - opcije i funkcionalnosti</div>
                            <div className="text-muted">
                              <i className="fas fa-calendar-alt createdDateFavicon"></i>08.09.2021
                              <i className="fas fa-user-edit createdDateBy"></i>BetaSoft
                            </div>
                          </div>
                        </div>
                      </a>
                      <a className="navi-item" href="https://drive.google.com/file/d/1kio10LF8rbrFD45nIY_O14lzVm2ziK9v/view?usp=sharing" target="_blank">
                        <div className="navi-link">
                          <div className="navi-icon mr-2">
                            <i className="far fa-file-pdf"></i>
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">BetaSoft - formiranje cenovnika</div>
                            <div className="text-muted">
                              <i className="fas fa-calendar-alt createdDateFavicon"></i>01.09.2021
                              <i className="fas fa-user-edit createdDateBy"></i>BetaSoft
                            </div>
                          </div>
                        </div>
                      </a>
                    </PerfectScrollbar>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
