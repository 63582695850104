import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  const selectedValue = field.value ? moment(field.value).toDate() : null;

  return (
    <>
      {props.label && <label>{props.label}</label>} <br />
      <DatePicker
        dateFormat="dd.MM.yyyy."
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={props.customValue ? moment(props.customValue).toDate() : selectedValue}
        value={selectedValue}
        onChange={val => {
          if (val !== null) {
            setFieldValue(field.name, moment(val).set({ hours: 12, minutes: 0, second: 0, millisecond: 0 }));
            if (props.onChange) {
              props.onChange(moment(val).set({ hours: 12, minutes: 0, second: 0, millisecond: 0 }));
            }
          } else {
            setFieldValue(field.name, null);
            if (props.onChange) {
              props.onChange(null);
            }
          }
        }}
      />
    </>
  );
}
