import React from "react";

export function NotificationFormatter(cellContent, row) {

    return (
        <>
            <span>
                {
                    cellContent ? <div className='text-success'>Da</div> : <div className='text-danger'>Ne</div>
                }
            </span>
        </>
    );
}
