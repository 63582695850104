import React from "react";

export function AmountFormatter(cellContent, row) {
    let dotColor = 'label-primary';

    if (cellContent > 50000) {
        dotColor = 'label-danger';
    } else if (cellContent > 40000) {
        dotColor = 'label-warning';
    } else if (cellContent > 20000) {
        dotColor = 'label-info';
    } else if (cellContent < 20000) {
        dotColor = 'label-success';
    }

    return (
        <>
            {/* {
                !row["PaymentGuid"] && <span className={`label label-dot ${dotColor} mr-1`}></span>
            } */}
            <strong>
                <span>
                    {
                        cellContent != null ? new Intl.NumberFormat('sr-RS', { style: 'currency', currency: 'RSD', minimumFractionDigits: 0, minimumFractionDigits: 2, }).format(cellContent) : ''
                    }
                </span>
            </strong>
        </>
    );
}
