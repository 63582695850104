/* eslint-disable no-unused-vars*/

/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { onFID } from 'web-vitals';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/performance";

import Gleap from 'gleap';

import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import moment from "moment-timezone";

import App from "./app/App";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import {
	MetronicLayoutProvider,
	MetronicSplashScreenProvider,
	MetronicSubheaderProvider
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGE_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MESS_ID,
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);

firebase.analytics();
firebase.performance();

Gleap.initialize("agjHbeNR9Ctv5cxAHXPE9l9NbEGg09SN");

moment.tz.setDefault('Europe/Belgrade');

onFID(console.log);

ReactDOM.render(
	<><ToastContainer
		position="top-right"
		autoClose={5000}
		hideProgressBar={false}
		newestOnTop={true}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		theme="light"
		transition={Slide}
		limit={2}
	/>
		<MetronicI18nProvider>
			<MetronicLayoutProvider>
				<MetronicSubheaderProvider>
					<MetronicSplashScreenProvider>
						<App store={store} persistor={persistor} basename={PUBLIC_URL} />
					</MetronicSplashScreenProvider>
				</MetronicSubheaderProvider>
			</MetronicLayoutProvider>
		</MetronicI18nProvider>
	</>,
	document.getElementById("root")
);
