import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import PhoneInput from 'react-phone-input-2'
import startsWith from 'lodash.startswith';

import 'react-phone-input-2/lib/bootstrap.css'

import { validatePhoneNumber } from "../../../../app/customAPICalls";

export function PhoneNumberField({ ...props }) {
    const { setFieldValue, errors, touched } = useFormikContext();
    const [field] = useField(props);
    const [phones, setPhones] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (props.field.value && props.field.value.length >= 12) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [props.field.value])

    const availableOptions = ['38160', '38161', '38162', '38163', '38164', '38165', '38166', '38167', '38168', '38169']

    async function checkPhoneNumber() {
        setLoader(true);
        setDisabled(true);

        if (!phones.some(f => f["phone"] === props.field.value)) {
            const result = await validatePhoneNumber(props.field.value);
            const phonesTemp = [...phones, { phone: props.field.value, valid: result }];
            setPhones(phonesTemp);
        }

        setLoader(false);

        setTimeout(() => {
            setDisabled(false);
        }, 5000);
    }

    return (
        <>
            {props.label && <label>{props.label}</label>} <br />
            <PhoneInput
                inputStyle={{ width: '100%' }}
                onlyCountries={['rs']}
                country={'rs'}
                regions={'europe'}
                enableAreaCodes={false}
                value={(field.value && field.value[props.field.name]) || null}
                inputProps={{ name: props.field.name }}
                masks={{ rs: '.. .. .. ...' }}
                onChange={(phoneNumber, country, e) => {
                    setFieldValue(props.field.name, phoneNumber);
                }}
                isValid={(inputNumber, country, countries) => {
                    return countries.some((country) => {
                        if (inputNumber.length > 3) {

                            if (inputNumber.length < 11) return false;

                            const countryOk = startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
                            return countryOk && availableOptions.find(f => f == inputNumber.substring(0, 5));
                        }

                        return true;
                    });
                }}
                {...props}
            />
            {props.showValidation && <><button
                disabled={disabled}
                className="btn btn-link btn-sm pl-0"
                onClick={checkPhoneNumber}>Proveri broj? </button> <ValidationResult phoneNumber={props.field.value} phones={phones} loader={loader} /></>}
        </>
    );
}

function ValidationResult({ phoneNumber, phones, loader }) {
    if (loader) return <>
        <div className="spinner-border spinner-border-sm mb-1"></div> <span> Provera u toku...</span>
    </>;

    const isValid = phones.find(f => f["phone"] === phoneNumber && f["valid"] == true);

    if (phones.length > 0 && isValid && phones.some(f => f["phone"] === phoneNumber)) {
        return <span><i className="fas fa-check text-success"></i> Validan broj telefona!</span>
    } else if (phones.length > 0 && !isValid && phones.some(f => f["phone"] === phoneNumber)) {
        return <span><i className="fas fa-times text-danger"></i> Broj telefona nije validan!</span>
    }

    return <></>;
}
