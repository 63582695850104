import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getPrice } from "../../_metronic/_helpers";
import moment from "moment";

export function IsMonthPayed(
    cellContent,
    row,
    rowIndex,
    { month }
) {
    let isCreated = false;

    try {
        isCreated = moment(month) >= moment(row["ContractDate"]);
    } catch (error) {

    }

    if (!isCreated) {
        return (<div className="text-center">
            <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">Kandidat nije bio upisan u ovom mesecu.</Tooltip>}
            >
                <div className="text-center">-</div>
            </OverlayTrigger>
        </div>);
    }

    return (
        <>
            {
                cellContent ?
                    <div className='text-success'>
                        <OverlayTrigger
                            overlay={<Tooltip id="products-edit-tooltip">Uplaceno {getPrice(cellContent)}</Tooltip>}
                        ><div className="text-center"><i className="fas fa-check text-success"></i></div>
                        </OverlayTrigger>
                    </div> :
                    <div><OverlayTrigger
                        overlay={<Tooltip id="products-edit-tooltip">Kandidat nije imao uplate u toku meseca</Tooltip>}
                    ><div className="text-center"><i className="fas fa-exclamation-circle text-warning"></i></div>
                    </OverlayTrigger></div>
            }
        </>
    );
}
