import React, { Suspense, lazy, useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { firebaseApp } from "../index";
import * as _redux from "../redux";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchUser } from "./modules/User/_redux/userActions";
import { LastChangedPasswordAlert } from "./components/LastChangedPasswordAlert";
import { snackActions } from '../SnackbarUtilsConfigurator';
import { QueryParamProvider } from 'use-query-params';
import firebase from "firebase";

import { srLatn } from "date-fns/esm/locale";

import axios2 from "./setupAxiosV2";
import axiosAuth2 from "./setupAxiosAuthV2";

import ContractPage from "./modules/Contracts/ContractsPage";
import { postToken } from "./customAPICalls";
import { UserNotifications } from "./UserNotifications";
import ChatPage from "./modules/Chat/ChatPage";
import { useScrollMemory } from "./hooks/useScrollMemory";

registerLocale("sr-RS", srLatn);
setDefaultLocale("sr-RS");

const ECommercePage = lazy(() =>
    import("./modules/ECommerce/pages/eCommercePage")
);

const CandidatesPage = lazy(() =>
    import("./modules/Candidates/CandidatePages")
);

const PaymentsPages = lazy(() =>
    import("./modules/Payments/PaymentsPages")
);

const ExamsPages = lazy(() =>
    import("./modules/Exams/ExamsPage")
);

const NotificationsPage = lazy(() =>
    import("./modules/Notifications/NotificationsPage")
);

const DashboardPage = lazy(() =>
    import("./modules/Dashboard/DashboardPage")
);

const CarsPage = lazy(() =>
    import("./modules/Cars/CarsPage")
);

const SettingsPage = lazy(() =>
    import("./modules/Settings/SettingsPage")
);

const HRPage = lazy(() =>
    import("./modules/HR/HRPage")
);

const InvoicePage = lazy(() =>
    import("./modules/Invoices/InvoicePage")
);

const SpacePage = lazy(() =>
    import("./modules/Space/SpacePage")
);

const AdminPage = lazy(() =>
    import("./modules/Admin/AdminPage")
);

const StatisticsPage = lazy(() =>
    import("./modules/Statistics/StatisticsPage")
);

const PriceListPage = lazy(() =>
    import("./modules/PriceList/PriceList")
);

const RoutePage = lazy(() =>
    import("./modules/Route/RoutePage")
);

const PCPage = lazy(() =>
    import("./modules/PC/Page")
);

const DocumentPage = lazy(() =>
    import("./modules/Documents/DocumentsPage")
);

const SMSPage = lazy(() =>
    import("./modules/SMS/SMSPage")
);
const scrollData = {};

export default function BasePage({ redirect }) {
    useScrollMemory(scrollData);

    const dispatch = useDispatch();

    const [isLoaded, setLoaded] = useState(null);

    useEffect(() => {
        firebase.auth().languageCode = 'sr';

        setInterval(() => {
            if (navigator.onLine) {
                snackActions.noInternet(false);
            } else {
                snackActions.noInternet(true);
            }
        }, 1000);
    }, []);

    useEffect(() => {

        firebaseApp.auth().onAuthStateChanged(user => {

            if (user && user.uid) {
                dispatch(fetchUser()).then(response => {
                    setLoaded(true);
                });
            }
        });

    }, []);

    if (isLoaded == null) {
        return (<LayoutSplashScreen />);
    }

    return (
        <>
            <UserNotifications />
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    {
                        /* Redirect from root URL to /dashboard. */
                        redirect && redirect.includes("redirect=") && <Redirect exact from="/" to={decodeURIComponent(redirect.split("redirect=")[1])} />
                    }
                    {
                        /* Redirect from root URL to /dashboard. */
                        <Redirect exact from="/" to="/pregled" />
                    }
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <Route path="/pregled" component={DashboardPage} />
                        <Route path="/kandidati" component={CandidatesPage} />
                        <Route path="/placanja" component={PaymentsPages} />
                        <Route path="/ispiti" component={ExamsPages} />
                        {/* <Route path="/notifikacije" component={NotificationsPage} /> */}
                        <Route path="/radnici" component={HRPage} />
                        <Route path="/vozila" component={CarsPage} />
                        <Route path="/podesavanja" component={SettingsPage} />
                        <Route path="/fakture" component={InvoicePage} />
                        <Route path="/prostor" component={SpacePage} />

                        <Route path="/administrator" component={AdminPage} />
                        <Route path="/statistika" component={StatisticsPage} />
                        <Route path="/cenovnik" component={PriceListPage} />
                        <Route path="/ugovori" component={ContractPage} />
                        <Route path="/rute" component={RoutePage} />
                        <Route path="/pc" component={PCPage} />
                        <Route path="/dokumenta" component={DocumentPage} />
                        <Route path="/sms" component={SMSPage} />
                        <Route path="/chat" component={ChatPage} />
                    </QueryParamProvider>
                    <Redirect to="error/error-v1" />
                </Switch>
            </Suspense>
        </>
    );
}

