import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { paymentSlice } from "../app/modules/Payments/_redux/paymentsSlice";
import { examSlice } from "../app/modules/Exams/_redux/examSlice";
import { carsSlice } from "../app/modules/Cars/_redux/carsSlice";
import { settingsSlice } from "../app/modules/Settings/_redux/settingsSlice";
import { hrSlice } from "../app/modules/HR/_redux/hrSlice";
import { invoicesSlice } from "../app/modules/Invoices/_redux/invoiceSlice";
import { spaceSlice } from "../app/modules/Space/_redux/spaceSlice";
import { userSlice } from "../app/modules/User/_redux/userSlice";
import { adminSlice } from "../app/modules/Admin/_redux/adminSlice";
import { priceListSlice } from "../app/modules/PriceList/_redux/priceListSlice";
import { routeSlice } from "../app/modules/Route/_redux/routeSlice";
import { slice as pcSlice } from "../app/modules/PC/_redux/slice";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	customers: customersSlice.reducer,
	products: productsSlice.reducer,
	remarks: remarksSlice.reducer,
	specifications: specificationsSlice.reducer,
	payments: paymentSlice.reducer,
	exams: examSlice.reducer,
	cars: carsSlice.reducer,
	settings: settingsSlice.reducer,
	hr: hrSlice.reducer,
	invoices: invoicesSlice.reducer,
	space: spaceSlice.reducer,
	user: userSlice.reducer,
	admin: adminSlice.reducer,
	priceList: priceListSlice.reducer,
	routes: routeSlice.reducer,
	pc: pcSlice.reducer
});

export function* rootSaga() {
	yield all([auth.saga()]);
}
