
import { useField } from 'formik';
import React, { useState, useEffect } from 'react';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { ModalProgressBar } from "../../../_partials/controls";
import { getPriceFloat, getFormatedDate, getPrice } from "../../../../_metronic/_helpers/AssetsHelpers"
import moment from "moment-timezone";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

export function PriceControl({
    name,
    prices,
    typeFk,
    categoryFk,
    field,
    alreadyHasCategories,
    form: {
        touched,
        errors,
    } }) {

    const [field1, _, helpers] = useField(field.name);
    const [showModal, setShowModal] = useState(false);
    const [selectedPriceObj, setSelectedPriceObj] = useState(null);

    useEffect(() => {
        if (field1.value) {
            const selected = prices.find(f => f["value"] == field1.value);
            setSelectedPriceObj(selected);
        }

    }, [field1.value, prices]);

    return (
        <>
            <button type="button" className="btn btn-light btn-lg btn-block" style={{ fontWeight: 'bold' }} onClick={() => setShowModal(true)}>{selectedPriceObj ? `${selectedPriceObj["data"]["PriceName"]} ${getPriceFloat(selectedPriceObj["data"]["Price"])} RSD` : "Odaberi Cenovnik"}</button>
            {showModal && <ChoosePrice
                show={showModal} onHide={() => { setShowModal(false) }}
                prices={prices}
                typeFk={typeFk}
                categoryFk={categoryFk}
                alreadyHasCategories={alreadyHasCategories}
                setPrice={val => {
                    helpers.setValue(val)
                    setShowModal(false)
                }}
                selectedPrice={field1.value}
            />}

            {true && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={errors[field.name]}
                    customFeedbackLabel={false}
                />
            )}
        </>
    );
}

function ChoosePrice({ show, onHide, actionsLoading, prices, typeFk, categoryFk, setPrice, selectedPrice, alreadyHasCategories }) {
    const [priceList, setPriceList] = useState([]);

    useEffect(() => {
        let filteredPrice = prices.filter(f => f["data"]["TypeFk"] == typeFk && f["data"]["CategoryFk"] == categoryFk);

        if (alreadyHasCategories) {
            let selectedAlreadyCategories = alreadyHasCategories.split(';');
            let onlyWithAlreadyCategories = prices.filter(f => f["data"]["TypeFk"] == typeFk && f["data"]["CategoryFk"] === categoryFk);
            let data = [];

            onlyWithAlreadyCategories.forEach(m => {
                if (m["data"]["AlreadyCategories"] && m["data"]["AlreadyCategories"].some(v => selectedAlreadyCategories.includes(v))) {
                    data.push({ ...m, betterPrice: true })
                } else {
                    data.push({ ...m, betterPrice: false })
                }
            });

            filteredPrice = data.sort(function (x, y) {
                return (x.betterPrice === y.betterPrice) ? 0 : x ? -1 : 1;
            });;
        }

        setPriceList(filteredPrice);
    }, [prices, alreadyHasCategories, categoryFk, typeFk]);

    function isDisabledButton(from, to, isPromno) {
        let disabled = false;

        if (moment() < moment(from)) {
            disabled = true;
        }

        if (isPromno) {
            if (!moment().isBetween(moment(from), moment(to))) {
                disabled = true;
            }
        }

        return disabled;
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
        >
            {actionsLoading && <ModalProgressBar />}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg" style={{ display: 'flex', flexDirection: 'row' }}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                        <div className='m-2'>
                            <p>* Odaberite cenovnik kanidata</p>
                            <p>* Crvenom bojom su obelezene kategorije koje kandidat poseduje</p>
                        </div>
                    </Tooltip>}>
                        <i className="fas fa-info-circle"></i>
                    </OverlayTrigger>
                    <h4 className='ml-2'>Odaberi Cenovnik</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {
                    priceList.length == 0 &&

                    <div className="d-flex align-items-center" style={{
                        "width": "100%",
                        "height": "100%",
                    }}>
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            <i style={{ fontSize: '70px' }} className="far fa-folder-open"></i>
                            <h6 className="mt-3">
                                Nemate Cenovnik za odabranu kategoriju
                            </h6>
                        </div>
                    </div>
                }

                <div className="list-group">
                    {
                        priceList.map(m => <button type="button" className={`list-group-item list-group-item-action ${selectedPrice && selectedPrice == m["value"] ? 'active' : ''}`} onClick={() => setPrice(m["value"])} disabled={isDisabledButton(m["data"]["ValidFrom"], m["data"]["ValidTo"], m["data"]["IsPromo"])}>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='row col-md-12'>
                                        <h4>{m["data"]["PriceName"]}</h4>
                                    </div>
                                    {m.data.PricePerType && <div className='row col-md-12 mt-2 mb-2'>
                                        {m.data.PricePerType.TO && <span>Cena TO: {getPrice(m.data.PricePerType.TO)}<br></br></span>}
                                        {m.data.PricePerType.PO && <span>Cena PO: {getPrice(m.data.PricePerType.PO)}<br></br></span>}
                                        {m.data.PricePerType.PricePerHourTO && <span>Cena časa TO: {getPrice(m.data.PricePerType.PricePerHourTO)} <br></br></span>}
                                        {m.data.PricePerType.PricePerHourPO && <span>Cena po času PO: {getPrice(m.data.PricePerType.PricePerHourPO)} <br></br></span>}
                                    </div>}
                                    <div>
                                        <p style={{
                                            color: isDisabledButton(m["data"]["ValidFrom"], m["data"]["ValidTo"], m["data"]["IsPromo"]) ? 'red' : ''
                                        }}>Vazi od {getFormatedDate(m["data"]["ValidFrom"])} do {m["data"]["ValidTo"] ? getFormatedDate(m["data"]["ValidTo"]) : "∞"}</p>
                                    </div>
                                    {m.data?.AlreadyCategories && <ShowCategories priceCategory={m["data"]["AlreadyCategories"]} hasCategories={alreadyHasCategories ? alreadyHasCategories.split(';') : []} />}
                                </div>
                                <div className='col-md-4 text-center'>
                                    {
                                        m["data"]["IsPromo"] && m["data"]["IsPromo"] > 0 ?
                                            <h6><i className="fas fa-stopwatch"></i> Promo cena</h6>
                                            : ""
                                    }
                                    <h4>{getPriceFloat(m["data"]["Price"])} RSD</h4>
                                </div>
                            </div>
                        </button>)
                    }
                </div>

                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate btn-block mt-2"
                >
                    <i className="far fa-window-close"></i>  Prekini
                </button>
            </Modal.Body >
            {/* <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        <i className="far fa-window-close"></i>  Prekini
                    </button>
                    <> </>
                    <button
                        type="button"
                        // onClick={deleteCustomers}
                        className="btn btn-danger btn-elevate"
                    >
                        <i className="fas fa-trash-alt"></i>   Obriši
                    </button>
                </div>
            </Modal.Footer> */}
        </Modal >
    );
}

function ShowCategories({ priceCategory, hasCategories }) {
    return <div>
        <span className='text-muted'>Ako poseduje kategorije: </span>
        {
            priceCategory.map(m => {

                if (hasCategories && hasCategories.some(s => s == m)) {
                    return <span className='ml-1' style={{ fontWeight: 'bold', fontSize: '18px', color: 'red' }}>{m}</span>
                }

                return <span className='text-muted ml-1'>{m}</span>
            })
        }
    </div>
}