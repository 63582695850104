/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { snackActions } from '../../../../../SnackbarUtilsConfigurator';

import { ChangePasswordModal } from "../../../../../app/components/ChangePasswordModal"
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { firebaseApp } from "../../../../../index";
import { logOutUser } from "../../../../../app/customAPICalls";
import { LinkPhone } from "./LinkPhone";

export function QuickUser() {
	const history = useHistory();

	const [showPhoneModal, setShowPhoneModal] = useState(false);
	const [phoneProvider, setPhoneProvider] = useState(false);
	const [googleProvider, setGoogleProvider] = useState(false);
	const [providers, setProviders] = useState([]);

	const { userInfo } = useSelector(
		(state) => ({
			userInfo: state.user.userInfo,
		}),
		shallowEqual
	);

	const [showPasswordChange, setShowPassword] = useState(false);

	useEffect(() => {
		if (userInfo) {
			onLoad()
		}
	}, [userInfo]);

	async function onLoad() {
		setPhoneProvider(false);
		setGoogleProvider(false);

		const user = firebase.auth().currentUser;

		if (user) {
			const userProvider = user.providerData;
			userProvider.forEach(element => {
				if (element.providerId == "phone") {
					setPhoneProvider(true);
				} else if (element.providerId == "google.com") {
					setGoogleProvider(true);
				}
			});

			setProviders(userProvider);
		}
	}

	async function unlinkAccount(provider) {
		try {
			await firebase.auth().currentUser.unlink(provider.providerId);
			snackActions.info("Uspešno ste uklonili nalog!", 2000);
			const toggle = document.getElementById("kt_quick_user_toggle");
			if (toggle) {
				toggle.click();
			}

			onLoad();

			return true;
		} catch (error) {
			//	console.log(error)
			snackActions.error("Nešto nije u redu. Molimo Vas pokušajte opet!", 2000);
		}
	}

	const logoutClick = async () => {
		const toggle = document.getElementById("kt_quick_user_toggle");
		if (toggle) {
			toggle.click();
		}

		await logOutUser();

		firebaseApp.auth().signOut().then(res => {
			history.push("/logout");
		});
	};

	async function addGoogleAccount() {
		try {
			const provider = new firebase.auth.GoogleAuthProvider();
			provider.setCustomParameters({ 'login_hint': 'email@gmail.com', });

			const googleProvider = new firebase.auth.GoogleAuthProvider();
			await firebase.auth().currentUser.linkWithPopup(googleProvider);
			onLoad();
			const toggle = document.getElementById("kt_quick_user_toggle");
			if (toggle) {
				toggle.click();
			}

			return true;
		} catch (error) {
			if (error.code === "auth/provider-already-linked" || error.code === "auth/email-already-in-use") {
				snackActions.error("Google nalog je vec povezan!", 2000);
			} else if (error.code === "auth/credential-already-in-use") {
				snackActions.error("Google nalog sa ovom email adresom se vec koristi!", 2000);
			}
		}
	}

	return (
		<div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
			<div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
				<h3 className="font-weight-bold m-0">
					Korisnički profil
				</h3>
				<a
					href="#"
					className="btn btn-xs btn-icon btn-light btn-hover-primary"
					id="kt_quick_user_close"
				>
					<i className="ki ki-close icon-xs text-muted" />
				</a>
			</div>

			<div
				className="offcanvas-content pr-5 mr-n5"
			>
				<div className="d-flex align-items-center mt-5">
					<div className="d-flex flex-column">
						<a
							href="#"
							className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
						>
							{userInfo?.firstName + ' ' + userInfo?.lastName}
						</a>
						<span className="navi-text text-muted text-hover-primary">
							{userInfo?.email}
						</span>
						<div className="text-muted mt-3">Administrator</div>
						{/* <div id="recaptcha-container"></div> */}
					</div>
				</div>

				<hr className="mt-10 mb-10"></hr>

				<div className="offcanvas-content pr-5 mr-n5 text-muted" >
					<label>Povežite Vaš broj telefona ili Google nalog i koristite ga za prijavu u naš sistem.</label>
					{
						phoneProvider == false && <button className="btn btn-default btn-bold btn-block mt-2 mb-2" onClick={() => setShowPhoneModal(true)}><i className="fas fa-phone"></i> Poveži broj telefona</button>
					}

					{
						googleProvider == false && <button className="btn btn-default btn-bold btn-block mt-2 mb-2" onClick={addGoogleAccount}><i className="fab fa-google"></i> Poveži Google nalog</button>
					}

					{
						providers.filter(f => f["providerId"] !== "password").map(m => {
							let icon = null;
							let acc = null;

							switch (m.providerId) {
								case "phone":
									icon = 'fas fa-phone';
									acc = m.phoneNumber;
									break;
								case "google.com":
									icon = 'fab fa-google';
									acc = m.email;
									break;
								default:
									break;
							}

							return <button className="btn btn-light-danger btn-bold btn-block mt-2 mb-2" onClick={() => unlinkAccount(m)}><i className={icon}></i> Ukloni nalog {acc}</button>
						})
					}
				</div>

				<hr className="mt-10 mb-10"></hr>

				<div className="offcanvas-content pr-5 mr-n5" >
					<button className="btn btn-light-warning btn-bold btn-block mt-2 mb-2" onClick={() => setShowPassword(true)}><i className="fas fa-lock"></i> Promeni lozinku</button>
					<button className="btn btn-light-primary btn-bold btn-block mt-2 mb-2" onClick={logoutClick}><i className="fas fa-sign-out-alt"></i> Odjavi se</button>
				</div>
			</div>
			{
				showPasswordChange &&
				<ChangePasswordModal
					show={showPasswordChange}
					onHide={() => setShowPassword(false)}
				/>
			}

			{
				showPhoneModal &&
				<LinkPhone show={showPhoneModal} onHide={() => {
					setShowPhoneModal(false)
					onLoad()
				}} />
			}
		</div >
	);
}
