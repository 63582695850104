import axios from "axios";
import { firebaseApp } from "../index";
import { snackActions } from '../SnackbarUtilsConfigurator';

const axiosAuth2 = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5002' : 'https://api.betasoft.rs'
});

axiosAuth2.interceptors.request.use(async config => {
    const u = firebaseApp.auth().currentUser;

    if (u) {
        config.headers.Authorization = await u.getIdToken();
        config.headers.common = {
            "Content-Type": "application/json"
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error)
});

axiosAuth2.interceptors.response.use((response) => response, (error) => {
    if (error && error.response && error.response.status == 401) {
        localStorage.removeItem("persist:v705-demo1-auth");
        window.location.reload();
    }

    throw error;
});

export default axiosAuth2;