/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import firebase from "firebase";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { PhoneNumberField } from "../../../../_partials/controls";
import OtpInput from 'react-otp-input';
import { snackActions } from '../../../../../SnackbarUtilsConfigurator';

import { ChangePasswordModal } from "../../../../../app/components/ChangePasswordModal"
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { firebaseApp } from "../../../../../index";

const initialValues = {
    phoneNumber: "+381",
};

const LoginSchema = Yup.object().shape({
    phoneNumber: Yup.string()
        .min(10, "Minimum 10 karaktera")
        .max(15, "Najvise 15 karaktera")
        .required("Morate uneti broj telefona!"),
});

export function LinkPhone({ show, onHide }) {
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState(null);
    const [verificationCode, setVerificcationCode] = useState(null);

    useEffect(() => {
        firebase.auth().languageCode = 'sr';

        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
            },
        }, firebaseApp);
    }, []);

    async function confirmCode() {
        if (verificationCode) {
            const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationCode, code);
            await firebase.auth().currentUser.updatePhoneNumber(phoneCredential);
            snackActions.info("Uspešno ste povezali broj telefona i od sada ga možete koristiti za prijavu!", 2000);

            onHide();

            const toggle = document.getElementById("kt_quick_user_toggle");
            if (toggle) {
                toggle.click();
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {/* {actionsLoading && <ModalProgressBar />} */}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Povežite broj telefona sa Vašim nalogom
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {verificationCode === null && <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    onSubmit={async (values) => {
                        try {
                            setLoading(true);

                            const provider = new firebase.auth.PhoneAuthProvider();
                            const result = await provider.verifyPhoneNumber(`+${values.phoneNumber}`, window.recaptchaVerifier)

                            if (result) {
                                setVerificcationCode(result)
                            }
                            setLoading(false);

                            return true;
                        } catch (error) {
                            //console.log("err ", error);
                            setLoading(false);
                            if (error.code === "auth/invalid-phone-number") {
                                snackActions.error("Broj telefona nije validan. Pokušajte opet!", 2000);
                            } else {
                                snackActions.error("Nešto nije u redu. Molimo Vas pokušajte opet!", 2000);
                            }
                        }
                    }}
                >
                    {({ handleSubmit, values, setValues }) => (
                        <Form>
                            <div className="form-group fv-plugins-icon-container">
                                <label>Unesite broj telefona</label>
                                <Field
                                    label=""
                                    name="phoneNumber"
                                    inputProps={{ name: 'phoneNumber' }}
                                    component={PhoneNumberField}
                                    customValidation
                                    showValidation={false}
                                    inputClass="form-control form-control-solid h-auto"
                                />
                            </div>

                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                <button
                                    disabled={loading || values.phoneNumber.length < 12}
                                    type="submit"
                                    className={`btn btn-primary btn-block font-weight-bold px-9 py-4`}
                                ><i className="fas fa-mobile-alt"></i>
                                    <span>Pošalji kod</span>
                                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>}

                {
                    verificationCode && <>
                        <p className="text-muted font-weight-bold">
                            Unesite verifikacioni kod koji ste dobili u SMS poruci.
                        </p>
                        <OtpInput
                            value={code}
                            shouldAutoFocus={true}
                            isInputNum
                            onChange={(val) => setCode(val)}
                            numInputs={6}
                            separator={<span>-</span>}
                            inputStyle={{ width: '100%', height: 60, textSize: 18, borderWidth: 0.5 }}
                        />
                    </>
                }

                {
                    verificationCode &&
                    <button
                        onClick={confirmCode}
                        disabled={code == null || code.length !== 6}
                        className={`btn btn-info btn-block font-weight-bold px-9 py-4 my-3`}
                    ><i className="fas fa-check-circle"></i>
                        <span>Potvrdi kod</span>
                    </button>
                }

                <div id="sign-in-button" />
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        disabled={false}
                        type="button"
                        onClick={onHide}
                        className="btn btn-light font-weight-bold px-9 py-4 my-3"
                    >
                        <i className="far fa-window-close"></i>  Prekini
                    </button>
                    <> </>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
