import { createSlice } from "@reduxjs/toolkit";

const initialCandidatesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    invoiceForEdit: undefined,
    invoiceStatus: [],
    paymentProcessors: [],
    invoicePaymentsEntities: [],
    invoicePaymentsTotalCount: 0,
    selectedInvoiceIds: []
};

export const callTypes = {
    list: "list",
    action: "action"
};


export const invoicesSlice = createSlice({
    name: "invoices",
    initialState: initialCandidatesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        invoiceFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        invoiceForEditFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.invoiceForEdit = action.payload.invoiceForEdit;
            state.error = null;
        },
        loadConfig: (state, action) => {
            state.invoiceStatus = action.payload.invoiceStatus;
            state.paymentProcessors = action.payload.paymentProcessors;
        },
        invoicePaymentsFetched: (state, action) => {
            state.invoicePaymentsEntities = action.payload.entities;
            state.invoicePaymentsTotalCount = action.payload.totalCount;
        },
        finishedCall: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
        },
        changeSelectedIds: (state, action) => {
            state.selectedInvoiceIds = action.payload.selectedInvoiceIds;
        },
    }
});
