import { createSlice } from "@reduxjs/toolkit";

const initialSpaceState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],  
    spaceForEdit: undefined,
    invoiceStatus: [],
    paymentProcessors: [],
    invoicePaymentsEntities: [],
    invoicePaymentsTotalCount: 0
};

export const callTypes = {
    list: "list",
    action: "action"
};


export const spaceSlice = createSlice({
    name: "space",
    initialState: initialSpaceState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        invoiceFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        spaceForEditFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.spaceForEdit = action.payload.spaceForEdit;
            state.error = null;
        }, 
        finishedCall:  (state, action) => {  
            state.listLoading = false;
            state.actionsLoading = false;
        }, 
    }
});
