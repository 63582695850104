
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import { getFormatedDateTime } from "../../../../_metronic/_helpers/AssetsHelpers";
import { fetchContracts, delContract, getPreSignedUrl } from "../../../customAPICalls";
import { CreateContract } from "../../../components/CreateContract";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import * as columnFormatters from "../../../ColumnFormatters";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";

import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";


import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { saveAs } from "file-saver";
import { TramOutlined } from "@material-ui/icons";

export function ContractsCard() {

    const dispatch = useDispatch();

    const [contracts, setContracts] = useState([]);
    const [showCreate, setShowCreatea] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteContract, setDeleteContract] = useState({ show: false, id: null });

    const [queryParams] = useState({
        sortOrder: "asc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 20
    });

    useEffect(() => {
        setLoading(true);
        fetchContracts().then(res => { setContracts(res); setLoading(false); });
    }, [showCreate, deleteContract]);

    const { user, } = useSelector(
        (state) => ({
            user: state.user.userInfo
        }),
        shallowEqual
    );

    let { totalCountPayments, listLoading } = {
        totalCountPayments: 0,
        listLoading: loading
    };

    async function downloadExample(m) {
        try {
            const signedUrl = await getPreSignedUrl({
                Key: `contractTemplates/${m.VirtualName}`,
                action: 'getObject'
            });

            const response = await (await fetch(signedUrl, { method: 'GET' })).arrayBuffer();
            downloadWord(response, m.FileName.replace(".docx", ""));

            return true;
        } catch (error) {
            setLoading(false);
        }
    }

    function downloadWord(content, name) {
        const zip = new PizZip(content);

        const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true
        });

        const out = doc.getZip().generate({
            type: "blob",
            mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });

        saveAs(out, `${name}.docx`);
    }

    const columns = [
        {
            dataField: "Id",
            text: "Id",
            sort: false,
            hidden: true
        },
        {
            dataField: "action",
            text: "",
            formatter: function (val, row) {
                return <>
                    <button type="button" className="btn btn-info btn-sm mr-3" onClick={() => downloadExample(row)}><i className="fas fa-download"></i></button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => setDeleteContract({ show: true, id: row["Id"] })}><i className="fas fa-trash-alt"></i></button>
                </>
            },
            classes: "pr-0",
            headerClasses: "text-right",
            style: {
                minWidth: "100px",
            },
        },
        {
            dataField: "ContractName",
            text: "Naziv ugovora",
            sort: false,
        },
        {
            dataField: "FileName",
            text: "Ime fajla",
            sort: false
        },
        {
            dataField: "CreatedBy",
            text: "Kreirao/la",
            sort: false,
        },
        {
            dataField: "CreatedDate",
            text: "Datum kreiranja",
            sort: TramOutlined,
            formatter: columnFormatters.DateColumnFormatter,
            sortCaret: sortCaret,
        },
    ];

    const sizePerPageList = [
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
    ];

    const paginationOptions = {
        custom: true,
        totalSize: contracts.length,
        sizePerPageList: sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
        paginationTotalRenderer: (from, to, size) => (
            <span className="react-bootstrap-table-pagination-total">
                Prikaz od {from} do {to}. Ukupno {size} zapisa.
            </span>
        ),
    };

    const rowClasses = (row, rowIndex) => {
        let classes = 'table-hov1';

        return classes;
    };

    return (
        <Card>
            <CardHeader title="Ugovori">
                <CardHeaderToolbar>
                    <button
                        type="button"
                        onClick={() => setShowCreatea(true)}
                        className="btn btn-primary btn-elevate"
                    >
                        <i className="fas fa-plus-circle"></i>  Novi ugovor
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                {/* <table class="table">
                    <thead>
                        <tr>
                            <th>Obrisi</th>
                            <th>Preuzmi primerak</th>
                            <th scope="col">Naziv ugovora</th>
                            <th scope="col">Ime fajla</th>
                            <th scope="col">Kreirao/la</th>
                            <th scope="col">Datum kreiranja</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contracts.map(m => <tr>
                                <th>
                                    <button type="button" class="btn btn-danger btn-sm" onClick={() => setDeleteContract({ show: true, id: m["Id"] })}><i className="fas fa-trash-alt"></i></button>
                                </th>
                                <th>
                                    <button type="button" class="btn btn-info btn-sm" onClick={() => downloadExample(m)}><i className="fas fa-download"></i></button>
                                </th>
                                <th scope="row">{m["ContractName"]}</th>
                                <td>{m["FileName"]}</td>
                                <td>{m["CreatedBy"]}</td>
                                <td>{getFormatedDateTime(m["CreatedDate"])}</td>
                            </tr>
                            )
                        }
                    </tbody>
                </table> */}
                <div className="mt-2">
                    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                        {({ paginationProps, paginationTableProps }) => {
                            return (
                                <Pagination
                                    isLoading={listLoading}
                                    paginationProps={paginationProps}
                                >
                                    <BootstrapTable
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center"
                                        bootstrap4
                                        columns={columns}
                                        data={contracts}
                                        keyField="Id"
                                        rowClasses={rowClasses}
                                        {...paginationTableProps}
                                    >
                                        <PleaseWaitMessage entities={contracts} />
                                        <NoRecordsFoundMessage entities={contracts} />
                                    </BootstrapTable>
                                </Pagination>
                            );
                        }}
                    </PaginationProvider>
                </div>
                {
                    showCreate && <CreateContract show={showCreate} onHide={() => setShowCreatea(false)} />
                }
            </CardBody>
            {deleteContract.show && <DeleteContract show={deleteContract.show} onHide={() => setDeleteContract({ show: false, id: null })} id={deleteContract.id} />}
        </Card>
    );
}

export function DeleteContract({ id, show, onHide }) {
    const [loading, setLoading] = useState(false);

    async function deleteContract() {
        setLoading(true);
        await delContract(id);
        setLoading(false);
        onHide();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            {loading && <ModalProgressBar />}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Obriši ugovor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loading && (
                    <span>Da li ste sigurni da želite da obrišete ugovor?</span>
                )}
                {loading && <span>Brisanje u toku...</span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        <i className="far fa-window-close"></i>  Prekini
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={deleteContract}
                        className="btn btn-danger btn-elevate"
                    >
                        <i className="fas fa-trash-alt"></i>   Obriši
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
