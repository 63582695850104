import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { logout, firebaseApp } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
};

const initialAuthState = {
  user: undefined
};

export const reducer = persistReducer(
  { storage, key: "v705-demo1-auth", whitelist: ["user"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { user } = action.payload;

        return { ...state, user };
      }


      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: user => ({ type: actionTypes.Login, payload: { user } }),
  logout: () => ({ type: actionTypes.Logout })
};

export function* saga() {
  yield takeLatest(actionTypes.Logout, function* loginSaga() {
    logout();
  });
}

