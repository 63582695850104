import React, { useEffect, useState, useCallback } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { firebaseApp } from "../../../index";
import { mockUsers } from './mock';
import { GiftedChat, Send } from 'react-gifted-chat';
import { getChatUsers } from '../../customAPICalls';
import { getPrice } from '../../../_metronic/_helpers';

export function ChatCard() {
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedChat, setChat] = useState(null);
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [admin, setAdmin] = useState(null);
    const [dbUsers, setDbUsers] = useState([]);

    const { userInfo } = useSelector(
        (state) => ({
            userInfo: state.user.userInfo,
        }),
        shallowEqual
    );

    useEffect(() => {
        // const subscriber = firebaseApp.firestore()
        //     .collection(`schools/3/messages`)
        //     .where('readed', '==', false)
        //     .orderBy('createdAt', 'desc')
        //     .onSnapshot((querySnapshot) => {
        //         const res = [];

        //         querySnapshot.forEach((documentSnapshot) => {
        //             const data = documentSnapshot.data();

        //             const message = {
        //                 ...data,
        //                 createdAt: new Date(documentSnapshot.data().createdAt.seconds * 1000)
        //             };

        //             res.push(message);
        //         });

        //         setMessages(res);
        //     });

        // return () => subscriber();
    }, []);

    useEffect(() => {
        firebaseApp.firestore()
            .collection('users')
            .where('schoolId', '==', userInfo.sId)
            .orderBy('lastMessageWrite', 'desc')
            .get()
            .then((querySnapshot) => {
                const res = [];

                querySnapshot.forEach((documentSnapshot) => {
                    const data = documentSnapshot.data();
                    res.push(data);
                });

                getChatUsers(res.map(m => m.id)).then(userResult => {
                    setDbUsers(userResult);
                });

                setUsers(res);
            })
    }, [userInfo && userInfo.sId]);

    useEffect(() => {
        if (selectedChat && selectedChat.UniqueId) {
            const subscriber = firebaseApp.firestore()
                .collection(`users/${selectedChat.UniqueId}/messages`)
                .orderBy('createdAt', 'desc')
                .onSnapshot((querySnapshot) => {
                    const res = [];

                    querySnapshot.forEach((documentSnapshot) => {
                        const data = documentSnapshot.data();

                        const message = {
                            ...data,
                            createdAt: new Date(documentSnapshot.data().createdAt.seconds * 1000)
                        };

                        res.push(message);
                    });

                    setMessages(res);
                });

            return () => subscriber();
        }
    }, [selectedChat && selectedChat.UniqueId]);

    useEffect(() => {
        if (userInfo) {
            setAdmin({
                _id: userInfo.uid, name: userInfo.name
            })
        }
    }, [userInfo]);

    function onSend(currMessage = []) {
        const newMessages = GiftedChat.append(currMessage, messages);
        setMessages(newMessages);
        sendMessage(currMessage);
    }

    async function sendMessage(message) {
        try {
            let batch = firebaseApp.firestore().batch();

            const documentId = firebaseApp.firestore().collection(`users/${selectedChat.UniqueId}/messages`).doc();

            const nycRef = firebaseApp.firestore().collection(`users/${selectedChat.UniqueId}/messages`).doc(documentId.id);
            batch.set(nycRef, { ...message[0], _id: documentId.id, admin: true });

            // Commit the batch
            await batch.commit();

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='row'>
            <div className='col-md-4'>
                <Card>
                    <CardHeader>
                        <input className='form-control mt-5' type={'text'} placeholder={'Pretrazi...'} />
                    </CardHeader>
                    <CardBody style={{ padding: 0 }}>
                        <ul className="list-group">
                            {
                                users && users.length > 0 ? users.map(m => {
                                    const dbUser = dbUsers.find(f => f.UniqueId == m.id);
                                    if (!dbUser) return <></>

                                    return (
                                        <li
                                            className="list-group-item d-flex flex-stack p-4"
                                            onClick={() => setChat(dbUser)}
                                            style={{ width: '100%', border: 0, backgroundColor: selectedChat && selectedChat.UniqueId == m.id ? 'lightgray' : 'white' }}>

                                            <div className="d-flex align-items-center">
                                                <div className="symbol symbol-45px symbol-circle">
                                                    <span className="symbol-label bg-light-warning text-warning fs-6 fw-bolder mr-2">{dbUser.ShortName}</span>
                                                </div>
                                                <div className="ms-5">
                                                    <a className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2" ><strong>{dbUser.FullName}</strong></a>
                                                    <div className="fw-bold text-gray-400" style={{ color: 'gray', fontWeigh: '400' }}>{dbUser.UniqueNumber}</div>
                                                </div>
                                            </div>
                                            {/* <div className="d-flex flex-column align-items-end ms-2">
                                                <span className="text-muted fs-7 mb-1">1 day</span>
                                                <span className="badge badge-sm badge-circle text-success">6</span>
                                            </div> */}
                                        </li>
                                    )
                                })
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: 30, flexDirection: 'column', alignItems: 'center' }}>
                                        <i className="fas fa-user-slash" style={{ fontSize: 60 }}></i>
                                        <span className='mt-2'>Trenutno nema kandidata koji je instalirao aplikaciju</span>
                                    </div>
                            }
                        </ul>
                    </CardBody>
                </Card>
            </div>
            <div className='col-md-8'>
                <Card>
                    <CardHeader>
                        {
                            selectedChat && <CardHeaderToolbar>
                                <ul className='list-group'>
                                    <li className='list-group-item'>{selectedChat.FullName}</li>
                                    <li className='list-group-item'>Iznos za uplatu {getPrice(selectedChat.AmountToPay)}</li>
                                </ul>
                            </CardHeaderToolbar>
                        }
                    </CardHeader>
                    <CardBody>
                        {
                            selectedChat && admin ? <div style={{ height: 500 }}>
                                <GiftedChat
                                    messages={messages}
                                    onSend={(messages) => onSend(messages)}
                                    inverted={true}
                                    placeholder="Poruka..."
                                    user={admin}
                                />
                            </div> : <div style={{ display: 'flex', justifyContent: 'center', padding: 30, flexDirection: 'column', alignItems: 'center' }}>
                                <i className="far fa-comment-alt" style={{ fontSize: 120 }}></i>
                                <span className='mt-2'>Odaberite Kandidata iz liste sa leve strane i zapocnite chat sa njim</span>
                            </div>
                        }
                    </CardBody>
                </Card>
            </div>
        </div >

    )
}

