// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function ActionsColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ openEditCustomerDialog, openDeleteCustomerDialog, downloadPDF, openRoutePage, downloadInvoice, user, disableEnableAdmin, openDeletePriceListDialog, setCandidatePassedTest, showPaymentList, sendEmail }
) {

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			{
				openEditCustomerDialog && <OverlayTrigger
					overlay={<Tooltip id="products-edit-tooltip">Izmeni</Tooltip>}
				><a
					title="Izmeni"
					className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
					onClick={() => openEditCustomerDialog(row)}
				>
						<span className="svg-icon svg-icon-md svg-icon-primary">
							<SVG
								src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
							/>
						</span>
					</a>
				</OverlayTrigger>
			}

			{
				downloadPDF && <OverlayTrigger
					overlay={<Tooltip id="products-edit-tooltip">Preuzmi PDF</Tooltip>}
				><a
					title="Uplate"
					className="btn btn-icon btn-light btn-hover-warning btn-sm mr-2"
					onClick={() => downloadPDF(row)}
				>
						<span className="svg-icon svg-icon-md svg-icon-warning">
							<i className="far fa-file-pdf" style={{ color: '#FFA800' }}></i>
						</span>
					</a>
				</OverlayTrigger>
			}

			{
				downloadInvoice && row["InvoiceId"] && <a
					title="Faktura"
					className="btn btn-icon btn-light btn-hover-warning btn-sm mr-2"
					onClick={() => downloadInvoice(row)}
				>
					<span className="svg-icon svg-icon-md svg-icon-warning">
						<i className="far fa-file-pdf" style={{ color: '#FFA800' }} ></i>
					</span>
				</a>
			}

			{
				downloadInvoice && sendEmail && row["InvoiceId"] && <a
					title="Posalji fakturu kao email"
					className="btn btn-icon btn-light btn-hover-success btn-sm mr-2"
					onClick={() => sendEmail(row)}
				>
					<span className="svg-icon svg-icon-md svg-icon-success">
						<i className="far fa-envelope text-success"></i>
					</span>
				</a>
			}

			{openRoutePage && <a
				title="Idi na kandidata"
				className="btn btn-icon btn-light btn-hover-danger btn-sm mr-2"
				onClick={() => openRoutePage(row)}
			>
				<span className="svg-icon svg-icon-md svg-icon-danger">
					<i className="fas fa-directions" style={{ color: '#3295FB' }}></i>
				</span>
			</a>}

			{
				showPaymentList && <OverlayTrigger
					overlay={<Tooltip id="products-edit-tooltip">Lista uplata</Tooltip>}
				><a
					title="Lista"
					className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
					onClick={() => showPaymentList(row)}
				>
						<span className="svg-icon svg-icon-md svg-icon-primary">
							<i className="fas fa-list"></i>
						</span>
					</a>
				</OverlayTrigger>
			}

			{openDeleteCustomerDialog && !row["IsSuperUser"] && <OverlayTrigger
				overlay={<Tooltip id="products-edit-tooltip">Obriši</Tooltip>}
			><a
				title="Obriši"
				className="btn btn-icon btn-light btn-hover-danger btn-sm"
				onClick={() => openDeleteCustomerDialog(row)}
			>
					<span className="svg-icon svg-icon-md svg-icon-danger">
						<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
					</span>
				</a></OverlayTrigger>}

			{openDeletePriceListDialog && row["NumberOfCandidates"] === 0 && <OverlayTrigger
				overlay={<Tooltip id="products-edit-tooltip">Obriši</Tooltip>}
			><a
				title="Obriši"
				className="btn btn-icon btn-light btn-hover-danger btn-sm mr-2"
				onClick={() => openDeletePriceListDialog(row)}
			>
					<span className="svg-icon svg-icon-md svg-icon-danger">
						<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
					</span>
				</a>
			</OverlayTrigger>
			}
		</div>
	);
}
