import React from "react";

export function ExamStatusColumnFormatter(cellContent, row) {

    function getColor(status) {
        if (status === 1) {
            return "label-light-danger";
        } else if (status === 4) {
            return "label-light-success";
        }

        return "label-light-info";
    }

    let style = "label label-lg label-inline";


    if (row["ExamStatusFk"] === 1) {
        style += (" " + "label-light-primary")
    } else if (row["ExamStatusFk"] === 2) {
        style += (" " + "label-light-warning")
    } else if (row["ExamStatusFk"] === 3) {
        style += (" " + "label-light-success")
    } else if (row["ExamStatusFk"] === 4) {
        style += (" " + "label-light-danger")
    }

    // U pripremi
    // U toku
    // Završen
    // Otkazan

    return (
        <div style={{ width: '100px' }}>
            <span className={style}>{cellContent}</span>
        </div>
    );
}
