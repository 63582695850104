import React from "react";
export function DateColumnFormatter(cellContent, row) {
    return (
        <>
            <span className={`font-bold}`}> 
                {cellContent ? new Intl.DateTimeFormat("sr-RS").format(new Date(cellContent)) : <></>}
            </span>
        </>
    );
}
