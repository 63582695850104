import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    features: [],
    roles: [],
    userInfo: {},
    lastChangedPassword: undefined,
    notifications: []
};

export const callTypes = {
    list: "list",
    action: "action"
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        userInfo: (state, action) => {
            const { features, roles, user, lastChangedPassword } = action.payload;

            state.features = features;
            state.roles = roles;
            state.userInfo = user;
            state.lastChangedPassword = lastChangedPassword;
        },
        fillNotifications: (state, action) => { 
            state.notifications = action.payload.notifications;
        },
    }
});
