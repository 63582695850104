import moment from "moment";
import { messaging } from "firebase";
import axios2 from "./setupAxiosV2";
import axiosAuth2 from "./setupAxiosAuthV2";

export const statusList = [
    { id: 1, label: 'Nije zapoceo/la obuku' },
    { id: 2, label: 'Teorijska obuka' },
    { id: 3, label: 'Prakticna obuka' },
    { id: 4, label: 'Zavrsena obuka' },
    { id: 5, label: 'Ispisan' }
];

export const fetchPaymentTypes = async () => {
    let response = await axios2.get(`/config/payment-types`);
    return response?.data;
};

export const fetchExamsConfig = async () => {
    let response = await axios2.get(`/config/exams`);
    return response?.data;
}

export const fetchVehicleConfig = async () => {
    let response = await axios2.get(`/config/vehicle`);
    return response?.data;
}


export const fetchDocTypes = async () => {
    let response = await axios2.get(`/config/doc-types`);
    return response?.data;
}

export const fetchUsersConfig = async () => {
    let response = await axios2.get(`/config/hr`);
    return response?.data;
}

export const fetchRecentActivities = async () => {
    let response = await axios2.get(`/dashboard`);
    return response?.data;
}

export const fetchLast30DaysNoPayment = async (startDate) => {
    let response = await axios2.get(`/dashboard/last30days?startDate=${moment(startDate).format("YYYY-MM-DD")}`);
    return response?.data;
}

export const fetchTasksInFuture = async () => {
    let response = await axios2.get(`/dashboard/tasksInFuture`);
    return response?.data;
}

export const getCandidatesCountFor12Months = async () => {
    let response = await axios2.get(`/dashboard/candidate-count-12-months`);
    return response?.data;
}

export const getPaymnetsSumFor12Months = async () => {
    let response = await axios2.get(`/dashboard/payments-12-months`);
    return response?.data;
}

export const getLast10Payments = async () => {
    let response = await axios2.get(`/dashboard/last-10-payments`);
    return response?.data;
}

export const getComparableYears = async (years) => {
    let response = await axios2.post(`/dashboard/compare-year`, years);
    return response?.data;
}

export const getExamsStatistic = async () => {
    let response = await axios2.get(`/dashboard/exam-stats`);
    return response?.data;
}

export const closeNotifications = async (notifications) => {
    let response = await axios2.put(`/notifications`, notifications);
    return response?.data;
}

export const postLoginInfo = async (data) => {
    let response = await axios2.post(`/notifications/login`, data);
    return response?.data;
}

export const fetchInvoicesConfig = async () => {
    let response = await axios2.get(`/config/invoice`);
    return response?.data;
}

export const searchByText = async (text) => {
    // let response = await axios.get(`/dashboard/search/${text}`);
    // return response?.data;
    return [];
}

export const archiveCandidate = async (id, arch) => {
    let response = await axios2.put(`/candidates/archive?id=${id}&archive=${arch}`);
    return response?.data;
}

export const ratesSummary = async (showPayedOnly) => {
    let response = await axios2.get(`/payments/rates?p=${showPayedOnly}`);
    return response?.data;
}

export const sendedSMSHistory = async () => {
    let response = await axios2.get(`/history/sms-sended`);
    return response?.data;
}

export const sendedSMSHistoryGrouped = async () => {
    let response = await axios2.get(`/history/sms-sended-grouped`);
    return response?.data;
}

export const fetchPriceCandidates = async (id) => {
    let response = await axios2.get(`/pricelist/candidates?id=${id}`);
    return response?.data;
}

export const fetchNotifications = async () => {
    let response = await axios2.get(`/notifications`);
    return response?.data;
}

export const fetchAddresses = async (address, city) => {
    let response = await axios2.post(`/config/candidate-address`, { city, address });
    return response?.data;
}

export const fetchAddressesByCity = async (city) => {
    let response = await axios2.post(`/config/candidate-address-city`, { city });
    return response?.data;
}

export const fetchContracts = async () => {
    let response = await axios2.get(`/contract`);
    return response?.data;
}

export const postContract = async (data) => {
    let response = await axios2.post(`/contract`, data);
    return response?.data;
}

export const getContractData = async (candidateId) => {
    let response = await axios2.get(`/contract/data?id=${candidateId}`);
    return response?.data;
}

export const delContract = async (id) => {
    let response = await axios2.delete(`/contract?id=${id}`);
    return response?.data;
}

export const validatePhoneNumber = async (phoneNumber) => {
    let response = await axiosAuth2.get(`/validation/phone?phoneNumber=${phoneNumber}`);
    return response?.data;
}

export const withoutPayments = async () => {
    let response = await axios2.get(`/payments/without-payments`);
    return response?.data;
}

export const sendPaymentNotifications = async (candidates, notificationDate) => {
    let response = await axios2.post(`/notifications/payments`, { candidates, notificationDate });
    return response?.data;
}

export const getPaymentNotifications = async (id) => {
    let response;
    if (!id) {
        response = await axios2.get(`/notifications/payments`);
    } else {
        response = await axios2.get(`/notifications/payments?candidateFk=${id}`);
    }

    return response?.data;
}

export const getCandidatesForHR = async (hrID) => {
    let response = await axios2.get(`/hr/candidates?id=${hrID}`);
    return response?.data;
}

export const postToken = async (token) => {
    let response = await axios2.post(`/notifications/token`, { token });
    return response?.data;
}

export const logOutUser = async () => {
    try {

        if (messaging.isSupported()) {
            const token = await messaging().getToken();
            await axios2.post(`/users/logout`, { token });
        }

        return true;
    } catch (error) {
        return true;
    }
}

export const logInUser = async () => {
    try {
        await axios2.post(`/users/login`);

        return true;
    } catch (error) {
        return true;
    }
}

export const removeSMS = async (id) => {
    let response = await axios2.put(`/notifications/remove-sms?id=${id}`);
    return response?.data;
}

export const sendCanidateSMS = async (id) => {
    let response = await axios2.post(`/candidates/info-sms?id=${id}`);
    return response?.data;
}

export const setCandidatePassedTest = async (id, passed) => {
    let response = await axios2.get(`/exams/candidates/passed-test?id=${id}&passed=${passed}`);
    return response?.data;
}

export const getPaymentInfoByPaymentId = async (paymentId) => {
    let response = await axios2.get(`candidates/info?paymentId=${paymentId}&candidateId=${null}`);
    return response?.data?.[0];
}

export const getPaymentCandidateById = async (candidateId) => {
    let response = await axios2.get(`candidates/info?paymentId=${null}&candidateId=${candidateId}`);
    return response?.data?.[0];
}

export const postDocument = async (data) => {
    let response = await axios2.post('/documents', data);
    return response?.data;
}

export const getDocumentsById = async (data) => {
    let response = await axios2.post('/documents/id', data);
    return response?.data;
}

export const getPreSignedUrl = async (data) => {
    let response = await axios2.post('/documents/link', data);
    return response?.data;
}

export const deleteDocument = async (data) => {
    let response = await axios2.delete(`/documents?id=${data.Id}`);
    return response?.data;
}

export const fetchDocuments = async () => {
    let response = await axios2.get(`/documents`);
    return response?.data;
}

export const fetchAdminEmails = async () => {
    let response = await axios2.get(`/invoice/admin-emails`);
    return response?.data;
}

export const sendInvoiceEmail = async (data) => {
    let response = await axiosAuth2.post(`/invoice/send-email`, data);
    return response?.data;
}

export const getChatUsers = async (users) => {
    let response = await axios2.post(`/candidate/chat-users`, { users });
    return response?.data;
}

export const reSendSMSMessages = async (examFk) => {
    let response = await axios2.post(`/exams/resend-sms`, { examFk });
    return response?.data;
}

export const getSendedEmails = async () => {
    let response = await axios2.get(`/history/sended-emails`);
    return response?.data;
}
