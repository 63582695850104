import { createSlice } from "@reduxjs/toolkit";

const initialCandidatesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],  
    hrForEdit: undefined,
    userRoles: [] 
};

export const callTypes = {
    list: "list",
    action: "action"
};


export const hrSlice = createSlice({
    name: "hr",
    initialState: initialCandidatesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        hrFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        hrForEditFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.hrForEdit = action.payload.hrForEdit;
            state.error = null;
        },
        loadConfig: (state, action) => { 
            state.userRoles = action.payload.userRoles;
        }, 
    }
});
