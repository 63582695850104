import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../_metronic/_partials/controls";
import { useDropzone } from 'react-dropzone'

import { getPreSignedUrl, postContract } from "../customAPICalls";

export function CreateContract({ show, onHide }) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({ accept: '.doc, .docx', maxFiles: 1, maxSize: 10485760 });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const dispatch = useDispatch();
    const [contractName, setContractName] = useState(null);
    const [loading, setLoading] = useState(false);

    const { user, } = useSelector(
        (state) => ({
            // actionsLoading: state.candidates.actionsLoading,
            user: state.user.userInfo
        }),
        shallowEqual
    );

    async function uploadFile() {
        try {
            setLoading(true);

            const splitedName = acceptedFiles[0].name.split(".");
            const extension = `.${splitedName[splitedName.length - 1]}`

            const fileName = uuidv4() + extension;

            const signedUrl = await getPreSignedUrl({
                Key: `contractTemplates/${fileName}`,
                action: 'putObject'
            });

            const response = await fetch(signedUrl, { method: 'PUT', body: acceptedFiles[0] });

            if (response.ok) {
                await postContract({
                    ContractName: contractName,
                    VirtualName: fileName,
                    FileName: acceptedFiles[0].name
                });

                setLoading(false);
                onHide();
            }

            return true;
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            size='lg'
        >
            {loading && <ModalProgressBar />}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Kreiraj ugovor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="row mb-3">
                    <button type="button" className="btn btn-link" onClick={() => {
                        window.open("https://drive.google.com/file/d/1TcFt2mXDca7lBEt3tNxKH1axQI1VFOjd/view?usp=sharing", "_blank")
                    }}>Kako da kreiram ugovor?</button>
                </div>

                <div className="mb-3">
                    <label for="contract" className="form-label">Naziv ugovora</label>
                    <input type="text" value={contractName} onChange={(val) => setContractName(val.target.value)} className="form-control" id="contract" placeholder="Ugovor ..." />
                </div>

                <div className="">
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>Prevuci fajl ovde ili klikni da odaberes fajl...</p>
                        <p>Fajl mora biti u formatu .doc ili .docx i ne sme biti veci od 10 megabajta!</p>
                    </div>
                    {acceptedFiles.length > 0 && <aside className="mt-2">
                        <h6>Odabrani fajl</h6>
                        <ul>{files}</ul>
                    </aside>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        <i className="far fa-window-close"></i>  Prekini
                    </button>
                    <button
                        disabled={loading}
                        type="button"
                        onClick={() => uploadFile()}
                        className="btn btn-primary btn-elevate ml-2"
                    >
                        <i className="fas fa-plus-circle"></i>  Napravi ugovor
                    </button>

                </div>
            </Modal.Footer>
        </Modal>
    );
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};