import { createSlice } from "@reduxjs/toolkit";

const initialCandidatesState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    paymentForEdit: undefined,
    lastError: null,
    payments: [],
    totalCountPayments: 0,
    paymentTypes: [],
    allPaymentTypes: [],
    paymentProcessors: [],
    paymentCandidate: null
};
export const callTypes = {
    list: "list",
    action: "action"
};


export const paymentSlice = createSlice({
    name: "payments",
    initialState: initialCandidatesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        // getCustomerById
        customerFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.candidateForEdit = action.payload.candidateForEdit;
            state.error = null;
        },
        // findCustomers
        paymentFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        paymentsFetched: (state, action) => {
            const { totalCountPayments, payments } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.payments = payments;
            state.totalCountPayments = totalCountPayments;
        },
        // createCustomer
        customerCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.customer);
        },
        // updateCustomer
        customerUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.customer.id) {
                    return action.payload.customer;
                }
                return entity;
            });
        },
        // deleteCustomer
        customerDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(el => el.id !== action.payload.id);
        },
        // deleteCustomers
        customersDeleted: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.filter(
                el => !action.payload.ids.includes(el.id)
            );
        },
        fetchPaymentTypes: (state, action) => {
            state.paymentTypes = action.payload.paymentTypes;
        },
        // customersUpdateState
        customersStatusUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            const { ids, status } = action.payload;
            state.entities = state.entities.map(entity => {
                if (ids.findIndex(id => id === entity.id) > -1) {
                    entity.status = status;
                }
                return entity;
            });
        },
        setPaymentTypes: (state, action) => {
            state.allPaymentTypes = action.payload.paymentTypes;
            state.paymentProcessors = action.payload.paymentProcessors;
        },
        paymentByIdFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.paymentForEdit = action.payload.paymentForEdit;
            state.error = null;
            state.paymentCandidate = null;
        },
        paymentCandidateByIdFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.paymentCandidate = action.payload.paymentCandidate;
        },
    }
});
