import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function FiscalNumberInput({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = false,
    customValidation = false,
    customFeedbackLabel,
    type = "text",
    prepand,
    categoryPrepand,
    selectedTransactionType,
    setTransactionType,
    ...props
}) {

    return (
        <>
            {label && <label>{label}</label>}
            <div className="input-group mb-3">
                <input
                    type={type}
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    {...field}
                    {...props}
                />
                <select className="form-control"
                    value={selectedTransactionType || "ПП"}
                    onChange={val => setTransactionType(val.target.value)}
                    id="exampleFormControlSelect1" style={{ maxWidth: 65 }}>
                    <option value={"ПП"}>ПП - (Промет Продаја)</option>
                    <option value={"ПР"}>ПР - (Промет Рефундација)</option>
                    <option value={"АП"}>АП - (Аванс Продаја)</option>
                    <option value={"АР"}>АР - (Аванс Рефундација)</option>
                </select>
                <div className="text-center mt-3 ml-2">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                        <div className='m-2'>
                            <p>* Odaberite kombinaciju fiskalnog računa i tipa transakcije</p>
                        </div>
                    </Tooltip>}>
                        <i className="fas fa-info-circle"></i>
                    </OverlayTrigger>
                </div>
            </div>
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}

            {customValidation && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={errors[field.name]}
                    customFeedbackLabel={false}
                />
            )}
        </>
    );
}
