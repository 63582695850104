import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { ContractsCard } from "./pages/ContractsCard";

export default function ContractPage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <ContentRoute path="/ugovori" component={ContractsCard} />
            </Switch>
        </Suspense>
    );
}
