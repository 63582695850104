/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Tab, Dropdown, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { closeNotifications } from "../../../../../app/customAPICalls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAdminNotifications } from "../../../../../app/modules/User/_redux/userActions";

import { animations, AnimateGroup, AnimateOnChange } from 'react-animation'
import 'react-animation/dist/keyframes.css'

import moment from "moment-timezone";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {

  const dispatch = useDispatch();

  const bgImage = toAbsoluteUrl("/media/bg/rsz_bg-1.jpg");
  const sound = toAbsoluteUrl("/media/notificationsound.mp3");
  var audio = new Audio(sound);

  const [key, setKey] = useState("Alerts");
  const [notifications, setNotifications] = useState([]);

  const [numberOfNotifications, setNumberOfNotifications] = useState(0);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.user }),
    shallowEqual
  );

  useEffect(() => {
    fetchData();
  }, [currentState.notifications]);

  async function fetchData() {

    let notifications = currentState.notifications;
    let unseen = 0;

    notifications = notifications.map(m1 => {
      let m = Object.assign({}, m1);

      if (m["StatusFk"] == 1) {
        unseen++;
      }

      if (m["Source"]) {
        if (m["TypeFk"] === 3) {
          m["Link"] = `/vozila/${m["Source"]}/izmeni`;
        } else if (m["TypeFk"] === 4) {
          m["Link"] = `/kandidati/${m["Source"]}/izmeni`;
        } else if (m["TypeFk"] === 5) {
          m["Link"] = `/placanja/${m["Source"]}/izmeni`;
        } else if (m["TypeFk"] === 6) {
          m["Link"] = `/ispiti/${m["Source"]}/izmeni`;
        } else if (m["TypeFk"] === 7) {
          m["Link"] = `/radnici/${m["Source"]}/izmeni`;
        } else if (m["TypeFk"] === 8) {
          m["Link"] = `/prostor/${m["Source"]}/izmeni`;
        }
      } else {
        m["Link"] = null;
      }

      m['createdBy'] = m['CreatedBy'] == 'system' ? 'sistem' : m['FirstName'] + ' ' + m['LastName']

      m["notyTime"] = moment(new Date(m["CreatedDate"])).tz("Europe/Belgrade").format("DD.MM.YYYY. HH:mm")

      return m;
    });

    if (unseen > 0) {
      audio.play();
    }

    setNotifications(notifications);
    setNumberOfNotifications(unseen);
  }

  async function seenNotification() {
    if (notifications) {
      const notificationIds = notifications.filter(f => f["StatusFk"] == 1).map(m => m["Id"]);

      if (notificationIds.length > 0) {
        await closeNotifications(notificationIds);
      }

      dispatch(fetchAdminNotifications());
    }
  }

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight onToggle={function (isOpen, event, metadata) {
          if (isOpen == false) {
            seenNotification();
          }
        }}>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  Notifikacije
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-danger"
                id="kt_quick_notifications_toggle"
              >
                <AnimateOnChange animationIn="bounceIn"
                  animationOut="bounceOut"
                  durationOut={500}>
                  <span className="svg-icon svg-icon-xl svg-icon-primary">

                    {numberOfNotifications > 0 &&
                      <Badge pill variant="danger" style={{ fontSize: '10px' }}>
                        {numberOfNotifications}
                      </Badge>
                    }

                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")}
                    />
                  </span>
                </AnimateOnChange>
                {
                  numberOfNotifications > 0 && <>
                    <span className="pulse-ring"></span>
                  </>
                }
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notifikacije</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2" onClick={() => { }}>
                    {numberOfNotifications} novih notifikacija
                  </span>
                </h4>

                <Tab.Container defaultActiveKey={key}>
                  <Tab.Content className="tab-content">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "500px", position: "relative" }}
                    >
                      {
                        notifications.map((m, i) => {
                          if (m["TypeFk"] === 5) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-psd flaticon2-line- text-success"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 4) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-user flaticon2-line- text-primary"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}

                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 3) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-delivery-truck flaticon2-line- text-info"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 2) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-line-chart flaticon2-line- text-danger"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 1) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-sms flaticon2-line- text-warning"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 6) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-information flaticon2-line- text-warning"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 7) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-user-1 flaticon2-line-" style={{ color: '#CD5C5C' }}></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 8) {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon2-architecture-and-city  flaticon2-line-" style={{ color: '#DE3163' }}></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else if (m["TypeFk"] === 10) {
                            return (
                              <a className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon-price-tag" style={{ color: '#DE3163' }}></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          } else {
                            return (
                              <a href={m["Link"]} className="navi-item" key={i} style={m["StatusFk"] == 1 ? { backgroundColor: 'aliceblue' } : {}}>
                                <div className="navi-link">
                                  <div className="navi-icon mr-2">
                                    <i className="flaticon-info flaticon2-line- text-warning"></i>
                                  </div>
                                  <div className="navi-text">
                                    <div className="font-weight-bold">{m["Content"]}</div>
                                    <div className="text-muted">
                                      <i className="fas fa-calendar-alt createdDateFavicon"></i>{m["notyTime"]}
                                      <i className="fas fa-user-edit createdDateBy"></i>{m["createdBy"]}
                                    </div>
                                  </div>
                                </div>
                              </a>)
                          }
                          return <></>
                        })
                      }
                    </PerfectScrollbar>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
