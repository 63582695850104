import { createSlice } from "@reduxjs/toolkit";

const initialCandidatesState = {
    listLoading: false,
    actionsLoading: false,  
    settingsForEdit: undefined,
   
};

export const callTypes = {
    list: "list",
    action: "action"
};


export const settingsSlice = createSlice({
    name: "settings",
    initialState: initialCandidatesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        stopCall: (state, action) => {
            state.error = null;
            state.listLoading = false;
            state.actionsLoading = false;
        },
        // getCustomerById
        settingFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.settingsForEdit = action.payload.settingsForEdit;
            state.error = null;
        }, 
        customerUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.customer.id) {
                    return action.payload.customer;
                }
                return entity;
            });
        }, 
    }
});
