import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { firebaseApp } from "../../../../index";
import { snackActions } from '../../../../SnackbarUtilsConfigurator';

const initialValues = {
	newPassword: "",
	newPassword1: "",
};

function ResetPassword(props) {

	const [loading, setLoading] = useState(false);
	const [isCodeOk, setIsCodeOk] = useState(false);
	const [btnDisabled, setbtnDisabled] = useState(true);

	let history = useHistory();
	let { code } = useParams();

	useEffect(() => {
		if (code) {
			firebaseApp.auth().verifyPasswordResetCode(code).then(res => {
				setbtnDisabled(false);
				setIsCodeOk(true);
			}).catch(err => {
				if (err.code == 'auth/invalid-action-code') {
					snackActions.error("Link za reset Vaše lozinke nije u redu. Molimo Vas da ponovo pošaljete zahtev za reset lozinke!", 5000);
				} else {
					snackActions.error("Nešto nije u redu. Molimo Vas pokušajte kasnije!", 5000);
				}

				history.push("/auth/login");
			});
		} else {
			snackActions.error("Link za reset Vaše lozinke nije u redu. Molimo Vas da ponovo pošaljete zahtev za reset lozinke!", 5000);
			history.push("/auth/login");
		}
	}, []);

	const LoginSchema = Yup.object().shape({
		newPassword: Yup.string()
			.min(8, "Minimum 8 karaktera")
			.max(20, "Najvise 20 karaktera")
			.matches("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$",
				"Minimum 8 karaktera, jedno veliko slovo, jedno malo slovo i jedan broj.")
			.required("Morate uneti novu lozinku!"),
		newPassword1: Yup.string()
			.min(8, "Minimum 8 karaktera")
			.max(20, "Najvise 20 karaktera")
			.oneOf([Yup.ref('newPassword'), null], "Lozinka nije ista!")
			.required("Ponovo unesite novu lozinku!"),
	});

	const enableLoading = () => {
		setLoading(true);
	};

	const disableLoading = () => {
		setLoading(false);
	};

	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};

	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			try {
				setbtnDisabled(true);
				const oob = code ? code.split("&apiKey")[0] : null;
				firebaseApp.auth().confirmPasswordReset(oob, values.newPassword).then((resp) => {
					snackActions.success("Uspešno ste promenili lozinku. Bićete prebačeni na stranu za login!", 3000)

					setTimeout(() => {
						history.push("/auth/login");
					}, 3000);

				}).catch((error) => {
					snackActions.error("Nešto nije u redu. Molimo Vas pokušajte kasnije!");
				});
			} catch (error) {
				snackActions.error("Nešto nije u redu. Molimo Vas pokušajte kasnije!");
			}
		},
	});

	return (
		<div className="login-form login-signin" id="kt_login_signin_form">
			{/* begin::Head */}

			{isCodeOk &&
				<>
					<div className="text-center mb-10 mb-lg-20">
						<h3 className="font-size-h1">
							Resetuj lozinku
						</h3>
						<p className="text-muted font-weight-bold">
							Unesite novu lozinku
						</p>
					</div>
					{/* end::Head */}

					{/*begin::Form*/}
					<form
						onSubmit={formik.handleSubmit}
						className="form fv-plugins-bootstrap fv-plugins-framework"
					>
						{formik.status ? (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold text-center">{formik.status}</div>
							</div>
						) : (
							<></>
						)}

						<div className="form-group fv-plugins-icon-container">
							<input
								placeholder="Nova lozinka"
								type="password"
								className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
									"password"
								)}`}
								name="newPassword"
								{...formik.getFieldProps("newPassword")}
							/>
							{formik.touched.newPassword && formik.errors.newPassword ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">{formik.errors.newPassword}</div>
								</div>
							) : null}
						</div>
						<div className="form-group fv-plugins-icon-container">
							<input
								placeholder="Ponovi novu lozinku"
								type="password"
								className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
									"password"
								)}`}
								name="newPassword1"
								{...formik.getFieldProps("newPassword1")}
							/>
							{formik.touched.newPassword1 && formik.errors.newPassword1 ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">{formik.errors.newPassword1}</div>
								</div>
							) : null}
						</div>
						<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
							<button
								id="kt_login_signin_submit"
								type="submit"
								disabled={formik.isSubmitting || btnDisabled}
								className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
							><i className="fas fa-sign-in-alt"></i>
								<span>Promeni lozinku</span>
								{loading && <span className="ml-3 spinner spinner-white"></span>}
							</button>
						</div>
					</form>
					{/*end::Form*/}
				</>
			}
		</div>
	);
}

export default injectIntl(connect(null, auth.actions)(ResetPassword));
