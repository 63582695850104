import * as requestFromServer from "./userCrud";
import { userSlice, callTypes } from "./userSlice";
import { fetchNotifications } from "../../../customAPICalls";
import { firebaseApp } from "../../../..";

const { actions } = userSlice;

export const fetchUser = () => async dispatch => {

    try {
        const userInfo = await requestFromServer.fetchUserInfo();

        const data = userInfo?.data;

        if (data) {
            let infoData = {
                features: data.features,
                roles: data.roles,
                user: data.user,
                lastChangedPassword: data.lastChangedPassword
            }
            dispatch(actions.userInfo(infoData));
        } else {
            // log out
        }

        return true;
    } catch (error) {
        return false;
    }
};

export const fetchAdminNotifications = () => async dispatch => {
    try {
        const data = await fetchNotifications();

        if (data) {
            dispatch(actions.fillNotifications({ notifications: data }));
        }

        return true;
    } catch (error) {
        return false;
    }
};