/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";

import { checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";

export function AsideMenuList({ layoutProps }) {

  const [isSuperUser, setIsSuper] = useState(false);


  const { userInfo } = useSelector(
    (state) => ({
      userInfo: state.user.userInfo,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (userInfo && userInfo["isSuperUser"]) {
      setIsSuper(userInfo["isSuperUser"]);
    }
  }, [userInfo])

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const getMenuItemActiveOvverided = (urlArray, hasSubmenu = false) => {
    let anyIsActive = false;

    urlArray.forEach(element => {
      if (checkIsActive(location, element)) {
        anyIsActive = true;
      }
    });

    return anyIsActive
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        <li
          className={`menu-item ${getMenuItemActive("/pregled", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pregled">
            <span className="svg-icon menu-icon">
              <i className="fas fa-layer-group"></i>
            </span>
            <span className="menu-text">Pregled</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/kandidati", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/kandidati">
            <span className="svg-icon menu-icon">
              <i className="fas fa-user-friends"></i>
            </span>
            <span className="menu-text">Kandidati</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/placanja", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/placanja">
            <span className="svg-icon menu-icon">
              <i className="fas fa-money-bill"></i>
            </span>
            <span className="menu-text">Plaćanja</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/fakture", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/fakture">
            <span className="svg-icon menu-icon">
              <i className="fas fa-file-invoice-dollar"></i>
            </span>
            <span className="menu-text">Fakture</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/ispiti", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ispiti">
            <span className="svg-icon menu-icon">
              <i className="fas fa-chalkboard-teacher"></i>
            </span>
            <span className="menu-text">Ispiti</span>
          </NavLink>
        </li>

        <li className="menu-section ">
          <h4 className="menu-text">OSTALO</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>

        {/*  ADMINISTARCIJA */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActiveOvverided(
            [
              "/radnici",
              "/vozila",
              "/prostor",
              "/rute",
              "/pc",
              "/dokumenta",
              "/sms"
            ], true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/administracija">
            <span className="svg-icon menu-icon">
              <i className="fas fa-tools"></i>
            </span>
            <span className="menu-text">Administracija</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/radnici"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/radnici">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Zaposleni</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/vozila"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/vozila">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Vozila</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/prostor"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/prostor">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Prostor</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/rute"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/rute">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Rute</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/pc"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/pc">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Računari</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/dokumenta"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/dokumenta">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Dokumenta</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive("/sms", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/sms">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">SMS poruke</span>
                  </NavLink>
                </li>

              </ul>
            </ul>
          </div>
        </li>

        {/*  PODESAVANJA */}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActiveOvverided(
            ["/podesavanja", "/ugovori"], true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/podesavanja1">
            <span className="svg-icon menu-icon">
              <i className="fas fa-cog"></i>
            </span>
            <span className="menu-text">Podešavanja</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <ul className="menu-subnav">
              <ul className="menu-subnav">
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/podesavanja"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/podesavanja">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Osnovna Podešavanja</span>
                  </NavLink>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/ugovori"
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/ugovori">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Ugovori</span>
                  </NavLink>
                </li>

              </ul>
            </ul>
          </div>
        </li>

        {/*  SUPER ADMIN */}
        {
          isSuperUser && <li
            className={`menu-item menu-item-submenu ${getMenuItemActiveOvverided(
              ["/administrator", "/cenovnik", "/statistika"], true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/admin">
              <span className="svg-icon menu-icon">
                <i className="fas fa-user-lock"></i>
              </span>
              <span className="menu-text">Administrator</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/administrator"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/administrator">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Administratori</span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/cenovnik"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/cenovnik">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Cenovnik</span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/statistika"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/statistika">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Statistika</span>
                    </NavLink>
                  </li>
                </ul>
              </ul>
            </div>
          </li>}
      </ul>
    </>
  );
}
