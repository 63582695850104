
import { useField } from 'formik';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

// const getFieldCSSClasses = (touched, errors) => {
//     const classes = [];

//     if (touched && errors) {
//         classes.push("react-select__control.react-select__control");
//     }

//     if (touched && !errors) {
//         classes.push("react-select__control.react-select__control_gre");
//     }

//     return classes.join(" ");
// };

export function SelectField(props) {
    const { name, options, form: {
        touched,
        errors
    } } = props;
    const [field, _, helpers] = useField(props.field.name);

    function getValue() {
        if (props.isMulti) {
            if (field.value && options.length > 0) {

                const selectedOptions = Array.isArray(field.value) ? field.value : field.value.split(";");
                let filterAvailableOptions = [];

                selectedOptions.forEach(e => {
                    let exist = options.filter(f => f["value"] === e.replace(/ /g, ''));
                    if (exist.length > 0) {
                        filterAvailableOptions = filterAvailableOptions.concat(exist);
                    } else {
                        filterAvailableOptions.push({
                            label: e,
                            value: e
                        });
                    }
                });

                return [...new Set(filterAvailableOptions)];;
            }
        } else {
            if (field.value === null) {
                return null;
            }

            return options ? options.find((option) => option.value === field.value) : ''
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <>
            <CreatableSelect
                // className={getFieldCSSClasses(props.form.touched[field.name], props.form.errors[field.name])}
                // classNamePrefix="react-select"
                options={options}
                name={field.name}
                value={getValue()}
                noOptionsMessage={() => `Nema opcija`}
                createOptionPosition={"last"}
                formatCreateLabel={(prop) => `Dodaj "${prop}"`}
                placeholder="Odaberi..."
                isValidNewOption={(s) => {
                    if (validateEmail(s)) {
                        return true;
                    }
                    return false;
                }}
                onChange={(option) => {
                    if (Array.isArray(option)) {
                        let selectedOptions = null;
                        let optionSelcetedArray = [];

                        option.forEach(f => {
                            optionSelcetedArray.push(f["value"]);
                        });

                        selectedOptions = optionSelcetedArray.join(";");
                        helpers.setValue(selectedOptions)
                    } else {
                        helpers.setValue(option ? option.value : null)
                    }

                    if (props.valueChanged) {
                        props.valueChanged(option ? option.value : null)
                    }
                }}
                onBlur={field.onBlur}
                isSearchable={props.isSearchable || false}
                isClearable={props.isClearable || false}
                {...props}
            />

            {true && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={errors[field.name]}
                    customFeedbackLabel={false}
                />
            )}
        </>
    );
}