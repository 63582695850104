import axios from "axios";

import { firebaseApp } from "../../../../index";
import { logInUser, postLoginInfo } from "../../../customAPICalls";
import axiosAuth2 from "../../../setupAxiosAuthV2";

export async function login(email, password) {
	const result = await firebaseApp.auth().signInWithEmailAndPassword(email, password);

	// if (process.env.NODE_ENV !== 'development') {
	// 	postInfo();
	// }

	if (result) {
		logInUser();
	}

	return result;
}

async function postInfo() {
	try {
		const ip = axios.create({
			baseURL: "https://ipapi.co/",
			timeout: 30000
		});

		const ipInfo = await ip.get(`/json`);

		if (ipInfo.data) {
			await postLoginInfo(ipInfo.data);
		}

		return true;
	} catch (error) {
		console.log("");
	}
}

export async function logout() {
	localStorage.removeItem("persist:v705-demo1-auth");
	return firebaseApp.auth().signOut();
}

export async function sendResetLink(email) {
	try {
		const resp = await axiosAuth2.get(`/auth/reset-password?email=${email}`);
		return resp.data;
	} catch (error) {
		return false;
	}
}
