import axios from "axios";
import moment from "moment";
import { firebaseApp } from "../index";

const LAST_TOKEN_EXPIRATION_TIME_MINUT = 60 * 30; // second * minutes

const axios2 = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:5001' : 'https://api.betasoft.rs'
});

let lastCall = null;

axios2.interceptors.request.use(async config => {
    config.headers.common = { "Content-Type": "application/json" };

    const u = firebaseApp.auth().currentUser;

    if (u) {
        try {

            if (lastCall) {

                const currTime = moment().utc().unix();
                const currTimeDiff = currTime - lastCall;

                // console.log("token currTimeDiff", currTimeDiff);

                if (currTimeDiff < LAST_TOKEN_EXPIRATION_TIME_MINUT) {
                    //   console.log("token already exists")
                    config.headers.Authorization = await u.getIdToken();
                } else {
                    //   console.log("token new")
                    config.headers.Authorization = await u.getIdToken(true);
                    lastCall = currTime;
                }
            } else {
                //  console.log("token new")
                config.headers.Authorization = await u.getIdToken(true);
                lastCall = moment().utc().unix();
            }

        } catch (error) {
            // console.log("token new error")
            config.headers.Authorization = await u.getIdToken(true);
            lastCall = moment().utc().unix();
        }
    }

    return config;
}, (error) => {
    lastCall = null;
    return Promise.reject(error)
});

axios2.interceptors.response.use((response) => {
    return response;
}, (error) => {
    lastCall = null;

    if (error && error.response && error.response.status == 401) {
        localStorage.removeItem("persist:v705-demo1-auth");
        window.location.reload();
    }

    throw error;
});

export default axios2;