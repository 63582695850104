import { createSlice } from "@reduxjs/toolkit";


const initialExamsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
    examForEdit: undefined,
    lastError: null,
    examTypes: [],
    examStatus: [],
    examsCandidates: [],
    totalExamCandidates: 0,
    examCandidates: [],
    candidatesListLoading: false
};

export const callTypes = {
    list: "list",
    action: "action"
};


export const examSlice = createSlice({
    name: "exams",
    initialState: initialExamsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
                state.candidatesListLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getCustomerById
        examFetched: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.examForEdit = action.payload.examForEdit;
            state.error = null;
        },
        // findCustomers
        examsFetched: (state, action) => {
            const { totalCount, entities } = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
            state.entities = entities;
            state.totalCount = totalCount;
        },
        paymentsFetched: (state, action) => {
            const { totalCountPayments, payments } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.payments = payments;
            state.totalCountPayments = totalCountPayments;
        },
        // createCustomer
        customerCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.entities.push(action.payload.customer);
        },
        // updateCustomer
        customerUpdated: (state, action) => {
            state.error = null;
            state.actionsLoading = false;
            state.entities = state.entities.map(entity => {
                if (entity.id === action.payload.customer.id) {
                    return action.payload.customer;
                }
                return entity;
            });
        },
        loadConfig: (state, action) => {
            state.examTypes = action.payload.examTypes;
            state.examStatus = action.payload.examStatus;
            state.examsCandidates = action.payload.examsCandidates;
        },
        examsCandidatesFetched: (state, action) => {
            state.actionsLoading = false;
            state.totalExamCandidates = action.payload.totalExamCandidates;
            state.examCandidates = action.payload.examCandidates;
            state.candidatesListLoading = false;
        },
        loadConfigExamsCandidate: (state, action) => {
            state.examsCandidates = action.payload.examsCandidates;
        },
        createNotifications: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
        },
    }
});
