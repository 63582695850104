import moment from 'moment-timezone';
const removeDiacritics = require('diacritics').remove;

export function removeCSSClass(ele, cls) {
	const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
	ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
	ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function getPrice(params) {
	return new Intl.NumberFormat('sr-RS', { style: 'currency', currency: 'RSD', minimumFractionDigits: 0, minimumFractionDigits: 2, }).format(parseFloat(params).toFixed(2))
}

export function getPriceFloat(params) {
	return new Intl.NumberFormat('sr-RS').format(parseFloat(params).toFixed(2))
}

export function getFormatedDate(date) {
	return moment(date).format("DD.MM.YYYY.")
}

export function getFormatedDateTime(date) {
	return moment(date).format("DD.MM.YYYY. HH:mm")
}

// Accepts the array and key
export const groupBy = (array, key) => {
	// Return the end result
	return array.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		(result[currentValue[key]] = result[currentValue[key]] || []).push(
			currentValue
		);
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result;
	}, {}); // empty object is the initial value for result object
};

export function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}


/**
 * 
 * @param {int} pageParam 
 * @param {array} entities 
 * @param {int} pageSize 
 * @returns 
 */
export function setTableActivePage(pageParam, entities, pageSize) {
	if (pageParam && entities.length > 0) {
		let page = parseInt(pageParam);
		let pageNumber = entities.length / pageSize;

		if (page <= Math.ceil(pageNumber) && page > 0) {
			return page
		}
	}

	return 1;
}

export async function getBlobFromMediaStreamChrome() {
	const stream = await navigator.mediaDevices.getDisplayMedia({
		video: { mediaSource: 'window' },
	});

	// get correct video track
	const track = stream.getVideoTracks()[0];

	try {
		// init Image Capture and not Video stream
		const imageCapture = new ImageCapture(track)
		// take first frame only
		const bitmap = await imageCapture.grabFrame()
		// destory video track to prevent more recording / mem leak
		track.stop()

		const canvas = document.getElementById('fake')
		canvas.width = bitmap.width
		canvas.height = bitmap.height
		const context = canvas.getContext('2d')
		context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
		const image = canvas.toDataURL()

		return image.split(",")[1];
	} catch (error) {
		track.stop()
	}
}

export async function getBlobFromMediaStreamFirefox() {

	if ('ImageCapture' in window) {
	} else {
		const stream = await navigator.mediaDevices.getDisplayMedia({
			video: { mediaSource: 'window' },
		});

		const track = stream.getVideoTracks()[0];

		const video = document.createElement('video');
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');

		video.srcObject = stream;

		return new Promise((resolve, reject) => {
			video.addEventListener('loadeddata', async () => {
				const { videoWidth, videoHeight } = video;
				canvas.width = videoWidth;
				canvas.height = videoHeight;

				try {
					await video.play();
					context.drawImage(video, 0, 0, videoWidth, videoHeight);
					track.stop();
					canvas.toBlob(resolve, 'image/png');
				} catch (error) {
					track.stop();
					reject(error);
				}
			});
		});
	}
}

export const blobToBase64 = blob => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

export function saveFile(data, fileName, mimeType) {
	var blob, url;
	blob = new Blob([data], {
		type: mimeType
	});
	url = window.URL.createObjectURL(blob);
	downloadURL(url, fileName);
	setTimeout(function () {
		return window.URL.revokeObjectURL(url);
	}, 1000);
};

export function downloadURL(data, fileName) {
	var a;
	a = document.createElement('a');
	a.href = data;
	a.download = fileName;
	document.body.appendChild(a);
	a.style = 'display: none';
	a.click();
	a.remove();
};

export function formatBytes(bytes, decimals = 2) {
	if (!+bytes) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function filterTableColumnWithDiacrets(cell) {
	try {
		if (cell) {
			let cellValue = cell.split(" ").map(m => m.trim());
			const text = cellValue.join(" ");

			return `${text}${text.replace(/\s/g, '')}${removeDiacritics(text)}${removeDiacritics(text.replace(/\s/g, ''))}`;
		}
	} catch (error) {
		return cell;
	}

	return cell;
} 