/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { TramOutlined } from "@material-ui/icons";
import React, { useState, useMemo, useEffect } from "react";
import 'react-animation/dist/keyframes.css'
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { DateTimeColumnFormatter } from "../../../../../app/ColumnFormatters";
import { getSendedEmails } from "../../../../../app/customAPICalls";
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret } from "../../../../_helpers";
import { ModalProgressBar, Pagination } from "../../../../_partials/controls";
const { SearchBar } = Search;
export function EmailsList() {

  const [showEmailList, setShowList] = useState(false);

  return (
    <div className="mr-2">
      <button className="btn btn-outline-primary" onClick={() => setShowList(true)}><i className="far fa-envelope-open"></i> Email-ovi</button>

      {
        showEmailList && <EmailsModalList onHide={() => setShowList(false)} show={showEmailList} />
      }

    </div>
  );
}

function EmailsModalList({ show, onHide }) {

  const [actionsLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getSendedEmails().then(res => {
      setLoading(false);
      setData(res);
    })
  }, [])

  const columns = [
    {
      dataField: "Receivers",
      text: "Primaoci email-a",
      sort: TramOutlined,
      sortCaret: sortCaret,
      style: {
        width: "200px",
      },
      formatter: (cellContent) => {
        return cellContent.replace(/;/g, '\n')
      }
    },
    {
      dataField: "SendAt",
      text: "Datum slanja",
      sort: TramOutlined,
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
    },
    {
      dataField: "Sub",
      text: "Naslov email-la",
    },
    {
      dataField: "Body",
      text: "Tekst email-a",
      formatter: (cellContent) => {
        return <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="products-edit-tooltip">
            <div dangerouslySetInnerHTML={{ __html: cellContent }} />
          </Tooltip>}
        >
          <div style={{ textAlign: 'center' }}>
            <i className="fas fa-search-plus"></i>
          </div>
        </OverlayTrigger>
      }
    }
  ];

  const sizePerPageList = [
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ];

  const paginationOptions = {
    custom: true,
    totalSize: data.length,
    sizePerPageList: sizePerPageList,
    sizePerPage: data.pageSize,
    page: data.pageNumber,
    paginationTotalRenderer: (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Prikaz od {from} do {to}. Ukupno {size} zapisa.
      </span>
    ),
  };

  const rowClasses = (row, rowIndex) => {
    let classes = 'table-hov1';

    return classes;
  };

  return <>
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Lista poslatih email-ova
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ToolkitProvider
          keyField="Id"
          data={data}
          columns={columns}
          search
        >
          {
            props => (
              <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <Pagination
                      isLoading={actionsLoading}
                      paginationProps={paginationProps}
                    >
                      <form className="form form-label-right">
                        <div className="form-group row">
                          <div className="col-lg-2">
                            <small className="form-text text-muted">
                              <b>Pretraga</b>
                            </small>
                            <SearchBar {...props.searchProps} placeholder="Pretrazi" />
                          </div>
                        </div>
                      </form>
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center"
                        bootstrap4
                        rowClasses={rowClasses}
                        {...paginationTableProps}
                        {...paginationTableProps}
                        {...props.baseProps}
                      >
                        <PleaseWaitMessage entities={data} />
                        <NoRecordsFoundMessage entities={data} />
                      </BootstrapTable>
                    </Pagination>
                  );
                }}
              </PaginationProvider>
            )
          }
        </ToolkitProvider>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            <i className="far fa-window-close"></i>  Nazad
          </button>
        </div>
      </Modal.Footer>
    </Modal></>
}
