import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { snackActions } from "../../../../SnackbarUtilsConfigurator";

const initialValues = {
    email: "",
    password: "",
};

function Login(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Pogrešan format email adrese!")
            .min(3, "Minimum 3 karaktera")
            .max(100, "Najvise 100 karaktera")
            .required("Morate uneti vašu email adresu!"),
        password: Yup.string()
            .min(8, "Minimum 8 karaktera")
            .max(20, "Najvise 20 karaktera")
            .required("Morate uneti vašu lozinku!"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                enableLoading();
                const response = await login(values.email, values.password);
                if (!response && !response.user) {
                    throw 'Error is happened!';
                }

                props.login(response.user, props.redirectUrl);
                disableLoading();
            } catch (error) {
                disableLoading();
                setSubmitting(false);
                setStatus(
                    "Pogrešna email adresa ili lozinka!"
                );
            }
        },
    });

    async function loginWithGoogle() {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.setCustomParameters({ 'login_hint': 'email@gmail.com' });

            const result = await firebase.auth().signInWithPopup(provider);

            if (result.user) {
                if (result.additionalUserInfo.isNewUser) {
                    snackActions.error("Ovaj Google nalog nije povezan ni sa jednim nalogom u aplikaciji.", 2000);
                    await firebase.auth().currentUser.delete();
                } else {
                    props.login(result.user, props.redirectUrl);
                }
            }

            return true;
        } catch (error) {
            // console.log(error)
            snackActions("Nešto nije u redu. Molimo Vas pokušajte kasnije!", 3000);
        }
    }

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">
                    Unesite Vašu email adresu ili iskoristite druge moguće načine da se prijavite na Vaš nalog
                </p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
            >
                {formik.status ? (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold text-center">{formik.status}</div>
                    </div>
                ) : (
                    <></>
                )}

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Email"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Lozinka"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                    <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary btn-block font-weight-bold `}
                    ><i className="fas fa-sign-in-alt"></i>
                        <span>Prijavi se</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>

                    <div className="text-center mt-5">
                        <Link
                            to="/auth/forgot-password"
                            className="text-dark-50 text-hover-primary my-3 mr-2"
                            id="kt_login_forgot"
                        >
                            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                        </Link>
                    </div>

                </div>
            </form>

            <p className="text-center mt-5">ili se prijavi sa</p>

            <button
                onClick={() => history.push("/auth/phone")}
                className={`btn btn-default btn-block font-weight-bold px-9 py-4 my-3 mt-5 `}
            ><i className="fas fa-phone"></i>
                <span>Broj telefona(ako je broj tel. povezan)</span>
            </button>
            <button
                onClick={loginWithGoogle}
                className={`btn btn-default btn-block font-weight-bold px-9 py-4 my-3`}
            ><i className="fab fa-google"></i>
                <span>Google nalog</span>
            </button>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
