import React from "react";
import { useHistory } from "react-router-dom";

export function useScrollMemory(scrollData) {
    const history = useHistory();

    React.useEffect(() => {
        const { push, replace } = history;

        // Override the history PUSH method to automatically set scroll state.
        history.push = (path) => {
            scrollData[history.location.pathname] = window.scrollY;
            push(path, { scroll: window.scrollY });
        };

        // Override the history REPLACE method to automatically set scroll state.
        history.replace = (path) => {
            scrollData[history.location.pathname] = window.scrollY;
            replace(path, { scroll: window.scrollY });
        };

        // Listen for location changes and set the scroll position accordingly.
        const unregister = history.listen((location, action) => {
            window.scrollTo(
                0,
                action !== 'POP' ? 0 : scrollData[location.pathname] ?? 0,
            );
        });

        // Unregister listener when component unmounts.
        return () => {
            unregister();
        };
    });
}
