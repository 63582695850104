/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { firebaseApp } from "../../index";

import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { fetchUser } from "../modules/User/_redux/userActions";

import firebase from 'firebase/app';
import 'firebase/auth';

const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmedPassword: ""
};

const LoginSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .min(8, "Minimum 8 karaktera")
        .max(20, "Najvise 20 karaktera")
        .required("Morate uneti trenutnu lozinku!"),

    newPassword: Yup.string()
        .min(8, "Minimum 8 karaktera")
        .max(20, "Najvise 20 karaktera")
        .matches("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$",
            "Minimum 8 karaktera, jedno veliko slovo, jedno malo slovo i jedan broj.")
        .required("Morate uneti novu lozinku!"),

    confirmedPassword: Yup.string()
        .min(8, "Minimum 8 karaktera")
        .max(20, "Najvise 20 karaktera")
        .oneOf([Yup.ref('newPassword'), null], "Lozinka nije ista!")
        .required("Ponovo unesite novu lozinku!"),
});

export function ChangePasswordModal({ show, onHide }) {

    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            try {
                setError(false);
                setSuccess(false);
                enableLoading();

                let res = await reauthenticate(values.oldPassword);
                let user = firebaseApp.auth().currentUser;

                if (user) {
                    let updatedPass = await user.updatePassword(values.newPassword);
                    dispatch(fetchUser()).then(response => {
                        setSuccess(true);
                        disableLoading();
                        setTimeout(() => { onHide() }, 3000);
                    });
                } else {
                    setSuccess(false);
                    setError(true);
                }
            } catch (error) {
                setSuccess(false);
                setError(true);
                disableLoading();
                setTimeout(() => { logoutClick() }, 3000);
            }
        },
    });

    async function reauthenticate(currentPassword) {
        try {
            var user = firebaseApp.auth().currentUser;
            var cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

            let res = await user.reauthenticateWithCredential(cred);

            return res;
        } catch (error) {
            throw new Error();
        }
    }

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const logoutClick = () => {
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
            toggle.click();
        }

        firebaseApp.auth().signOut();
        history.push("/logout");
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >  <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
        >
                {/* {actionsLoading && <ModalProgressBar />} */}
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Promeni lozinku
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {error ? (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold text-center">Uneli ste pogrešnu staru šifru i iz sigurnosnih razloga bićete izlogovani!</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    {success ? (
                        <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                            <div className="alert-text font-weight-bold text-center">Uspešno ste promenili lozinku! Prozor za promenu lozinke će se automatski zatvoriti!</div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="form-group row">
                        <div className="col-lg-12">
                            <label>Trenutna lozinka</label>
                            <input
                                placeholder="Lozinka"
                                type="password"
                                name="oldPassword"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "oldPassword"
                                )}`}
                                {...formik.getFieldProps("oldPassword")}
                            />
                            {formik.touched.oldPassword && formik.errors.oldPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.oldPassword}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <label>Nova lozinka</label>
                            <input
                                placeholder="Lozinka"
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "newPassword"
                                )}`}
                                name="newPassword"
                                {...formik.getFieldProps("newPassword")}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.newPassword}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            <label>Ponovite novu lozinku</label>
                            <input
                                placeholder="Lozinka"
                                type="password"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "confirmedPassword"
                                )}`}
                                name="confirmedPassword"
                                {...formik.getFieldProps("confirmedPassword")}
                            />
                            {formik.touched.confirmedPassword && formik.errors.confirmedPassword ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.confirmedPassword}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button
                            disabled={success || loading}
                            type="button"
                            onClick={onHide}
                            className="btn btn-light font-weight-bold px-9 py-4 my-3"
                        >
                            <i className="far fa-window-close"></i>  Prekini
                        </button>
                        <> </>
                        <button
                            disabled={success || loading}
                            type="submit"
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <i className="fas fa-sync-alt"></i>
                            <span>Promeni</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>)
}
