import React, { Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../../../_metronic/layout";
import { firebaseApp } from "../../../index";
import { ChatCard } from "./ChatCard";

export default function ChatPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(actions.getVehicleConfig());
    }, []);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* <ContentRoute path="/chat" component={} /> */}
                <ChatCard />
            </Switch>
        </Suspense>
    );
}
