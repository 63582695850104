import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function InputPrepand({
    field,
    form: { touched, errors },
    label,
    withFeedbackLabel = false,
    customValidation = false,
    customFeedbackLabel,
    type = "text",
    prepand,
    categoryPrepand,
    yearValue,
    setYear,
    ...props
}) {
    return (
        <>
            {label && <label>{label}</label>}
            <div className="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">{prepand}</span>
                </div>
                <select className="form-control"
                    value={yearValue}
                    onChange={val => setYear(val.target.value)}
                    id="exampleFormControlSelect1" style={{ maxWidth: 65 }}>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                </select>
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">{categoryPrepand}</span>
                </div>
                <input
                    type={type}
                    className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                    {...field}
                    {...props}
                />
                <div className="text-center mt-3 ml-2">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                        <div className='m-2'>
                            <p>* Odaberite godinu i upisite jedinstveni broj kandidata</p>
                        </div>
                    </Tooltip>}>
                        <i className="fas fa-info-circle"></i>
                    </OverlayTrigger>
                </div>
            </div>
            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}

            {customValidation && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={errors[field.name]}
                    customFeedbackLabel={false}
                />
            )}
        </>
    );
}
