import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { firebaseApp } from "../../../../index";
import { sendResetLink } from "../_redux/authCrud";
import { snackActions } from '../../../../SnackbarUtilsConfigurator';


const initialValues = {
  email: "",
};

function ForgotPassword(props) {

  const [isRequested] = useState(false);
  let history = useHistory();

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Pogrešan format email adrese!")
      .min(3, "Minimum 3 karaktera")
      .max(100, "Najvise 100 karaktera")
      .required("Morate uneti vašu email adresu!"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const response = await sendResetLink(values.email);

        if (response.userDoesntExist) {
          snackActions.error("Nešto nije u redu. Korisnik sa ovim email-om ne postoji!!", 7000);
        } else if (!response) {
          snackActions.error("Nešto nije u redu. Molimo Vas pokušajte kasnije!", 7000);
        } else {
          snackActions.success("Uskoro ćete dobiti email sa linkom za reset Vaše lozinke(slanje email-a može traje nekoliko minuta!).", 10000);
        }
        history.push("/auth/login");
      } catch (error) {
        setSubmitting(false);
        snackActions("Nešto nije u redu. Molimo Vas pokušajte kasnije!", 3000);
        history.push("/auth/login");
      }
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Zaboravljena lozinku?</h3>
            <div className="text-muted font-weight-bold">
              Unesite Vašu email adresu da biste resetovali lozinku
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                placeholder="Email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Nastavi
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Nazad
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
