import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { createFilter, components } from 'react-select';
import { debounce } from "lodash";
import VirtualList from 'react-tiny-virtual-list';
import { FixedSizeList as List } from 'react-window';

import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

import { fetchAddresses, fetchAddressesByCity } from "../../../../app/customAPICalls";

export function SelectAddress(props) {

    const [values, setValues] = useState({
        address: null,
        number: ''
    });

    const [ddlOptions, setOptions] = useState();
    const [loading, setLoading] = useState(false);

    const { name, options, form: {
        touched,
        errors
    } } = props;
    const [field, _, helpers] = useField(props.field.name);

    useEffect(() => {
        if (props.city) {
            setLoading(true);
            fetchAddressesByCity(props.city).then(res => {
                setOptions(res.map(m => {
                    return { label: m["Ulica"], value: m["Ulica"] }
                }));

                setLoading(false);
            });
        }
    }, [props.city]);

    return (
        <>
            <div className='row'>
                <div className='col-md-8'>
                    <label>Naselje, Ulica</label>
                    <ReactSelect
                        components={{ MenuList }}
                        filterOption={createFilter({ ignoreAccents: false })}
                        isDisabled={false}
                        isLoading={loading}
                        isClearable={true}
                        isSearchable={true}
                        options={ddlOptions}
                        placeholder="Pretrazi ulice..."
                        noOptionsMessage={() => `Nema ulice sa ukucanim tekstom`}
                        onChange={(val) => {
                            const tValue = val && val.value ? val.value : null;

                            setValues({
                                ...values, address: tValue
                            });

                            props.valueChanged({
                                ...values, address: tValue
                            });
                        }}
                    />
                </div>
                <div className='col-md-4'>
                    <label>Broj</label>
                    <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Broj" onChange={val => {
                        setValues({
                            ...values, number: val.target.value
                        });
                        props.valueChanged({
                            ...values, number: val.target.value
                        });
                    }} />
                </div>
            </div>

            {/* {true && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={errors[field.name]}
                    customFeedbackLabel={false}
                />
            )} */}
        </>
    );
}

const height = 40;

class MenuList extends React.Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => <div style={style}>{children[index]}</div>}
            </List>
        );
    }
}
