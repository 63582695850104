// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function AddItemFormatter(
	cellContent,
	row,
	rowIndex,
	{ addItem }
) {

	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<a
				title="Dodaj kandidata na listu obavestenja"
				className="btn btn-icon btn-light btn-hover-info btn-sm mr-2"
				onClick={() => addItem(row)}
			>
				<span className="svg-icon svg-icon-md svg-icon-info">
					<i className="fas fa-bell"  ></i>
				</span>
			</a>
		</div>
	);
}
