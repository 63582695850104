import React from "react";

export function StatusColumnFormatter(cellContent, row) {

    function getColor(status) {
        if (status === 1) {
            return "label-light-danger";
        } else if (status === 4) {
            return "label-light-success";
        }

        return "label-light-info";
    }

    return (
        <span>
            {cellContent}
        </span>
    );
}
