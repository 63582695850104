import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAdminNotifications } from "../app/modules/User/_redux/userActions";

import 'react-toastify/dist/ReactToastify.css';
import { snackActions } from '../SnackbarUtilsConfigurator';

export function UserNotifications({ }) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAdminNotifications());
    }, []);

    return <></>
}
