import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import CurrencyInput from 'react-currency-input-field';
import { useField, useFormikContext } from "formik";

const getFieldCSSClasses = (touched, errors) => {

    const classes = ["form-control"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

export function InputCurrency({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = false,
    customValidation = false,
    placeholder = "",
    customFeedbackLabel,
    type = "text",
    changeListener,
    ...props
}) {
    const { setFieldValue } = useFormikContext();

    return (
        <>
            {label && <label>{label}</label>}
            <CurrencyInput
                id={field.name}
                name={field.name}
                className={getFieldCSSClasses(touched[field.name], errors[field.name])}
                placeholder={placeholder}
                value={field.value || 0}
                onValueChange={(value, name) => {
                    if (value === undefined) value = 0.00;
                    if (typeof value == 'string' && "," !== value.slice(-1)) {
                        value = parseFloat(value.replace(",", "."));
                        setFieldValue(field.name, parseFloat(value));

                        if (changeListener) {
                            changeListener(parseFloat(value))
                        }

                        return;
                    }

                    setFieldValue(field.name, value);
                }}
                // prefix="RSD" 
                groupSeparator="."
                decimalSeparator=","
                // maxLength={6}
                min={0}
                allowNegativeValue={false}
                decimalsLimit={2}
                label={label}
                {...props}
            />

            {withFeedbackLabel && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={label}
                    type={type}
                    customFeedbackLabel={customFeedbackLabel}
                />
            )}

            {customValidation && (
                <FieldFeedbackLabel
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    label={errors[field.name]}
                    customFeedbackLabel={false}
                />
            )}
        </>
    );
}
